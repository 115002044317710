export const EnterArrowIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.5 6.78892L11.5 6.83325C11.5 8.76642 9.93317 10.3333 8 10.3333L7.17225 10.3333L4.5 10.3333"
				stroke="#227ED3"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5002 6.78662L11.5002 3.91662"
				stroke="#227ED3"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.25 8.5835L4.5 10.3335L6.25 12.0835"
				stroke="#227ED3"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
