/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CompanyTitle } from "../../components/companyTitle/CompanyTitle";
import { Footer } from "../../components/footer/Footer";
import Slider from "../../components/slider/Slider";
import { useForm } from "react-hook-form";
import Input from "../../components/input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { useState } from "react";
import RestartIcon from "../../assets/icons/RestartIcon";
import { useNavigate } from "react-router-dom";
import BookAppointment from "../../components/bookAppointment/BookAppointment";
import * as yup from "yup";
import axios from "axios";
import Chart from "../../components/chart/Chart";
import Dropdown from "../../components/dropdown/Dropdown";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { divideNumberByGrades } from "../../utils/helpers/divideNumberByGrades";
import { getCompanyNameByDomain } from "../../utils/helpers/getCompanyNameByDomain";

const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("Please enter your first name"),
  lastName: yup.string().trim().required("Please enter your last name"),
  email: yup
    .string()
    .trim()
    .matches(new RegExp(EMAIL_REGX), "Please enter the valid email")
    .required("Please enter your email"),
  phone: yup
    .string()
    .test(
      "is-minimum-10-numbers",
      "At least 10 numbers are required",
      (value) => {
        const numberOfDigits = (value?.match(/\d/g) || []).length;
        return numberOfDigits >= 10;
      }
    )
    .required("Please enter your phone number"),
  state: yup.string().required("Please select your state"),
});

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
}

export enum FormStep {
  FORM,
  BOOKING,
}

const ResultPage = () => {
  const toast = useToast();
  const { width } = useWindowSize();
  const companyName = getCompanyNameByDomain();

  const sessionStorageCalculationData =
    sessionStorage.getItem("CALCULATION_DATA");
  const calculationData = sessionStorageCalculationData
    ? JSON.parse(sessionStorageCalculationData)
    : null;
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(FormStep.FORM);
  const [contactCreateLoading, setContactCreateLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    trigger,
    clearErrors,
    setValue,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      state: "",
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormValues) => {
    setContactCreateLoading(true);
    sessionStorage.setItem("CONTACT_NAME", data.firstName);
    sessionStorage.setItem("CONTACT_SURNAME", data.lastName);
    sessionStorage.setItem("CONTACT_EMAIL", data.email);
    sessionStorage.setItem("CONTACT_PHONE", data.phone);
    sessionStorage.setItem("CONTACT_STATE", data.state);
    axios
      .post("https://api.superstrategyreview.com.au/api/contact", {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        super_balance: Number(
          sessionStorage.getItem("FIRST_STEP_RANGE_VALUE_KEY")
        ),
        age: Number(sessionStorage.getItem("age")),
        income: Number(sessionStorage.getItem("SIXTH_STEP_RANGE_VALUE_KEY")),
        desired_weekly_retirement_income: Number(
          sessionStorage.getItem("valuePerWeek")
        ),
        super_fund_type: sessionStorage.getItem("SUPER_FUND"),
        employment_status: sessionStorage.getItem("E_STATUS"),
        super_fund_name: sessionStorage.getItem("FUND"),
        state: data.state,
      })
      .then(function (response) {
        if (response) {
          sessionStorage.setItem("CONTACT_ID", response.data.id);
          setContactCreateLoading(false);
          setFormStep(FormStep.BOOKING);
        }
      })
      .catch(function () {
        toast({
          title: `Something went wrong, try to use valid email`,
          status: "error",
          isClosable: true,
          position: "bottom-right",
        });

        setError("email", {
          type: "custom",
          message: "Please enter the valid email",
        });
        setContactCreateLoading(false);
      });
  };

  return (
    <>
      {calculationData && (
        <>
          <Box bg={"blue"} pb={10} as="main">
            <Flex
              h={{ base: "56px", tablet: 20 }}
              borderBottom={"2px solid"}
              borderColor={"border"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CompanyTitle maxW="170px" />
            </Flex>
            <Box px={{ base: "16px", tablet: "40px", xl: "80px" }} as="section">
              <Container maxW={"1520px"} p={0}>
                {calculationData.is_qualified ? (
                  <>
                    <Flex
                      mb={{ base: 8, tablet: 10, xl: 20 }}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Heading
                        mt={{ base: 4, xl: 8 }}
                        mb={{ base: 4, md: 6 }}
                        size={{ base: "mobile", tablet: "md", xl: "lg" }}
                      >
                        Congratulations!
                      </Heading>
                      <Text
                        fontSize={{ base: "18px", tablet: "20px", xl: "24px" }}
                        lineHeight={{
                          base: "25.2px",
                          tablet: "28px",
                          xl: "34px",
                        }}
                        letterSpacing={"-0.01em"}
                        color={"dark"}
                        maxW={"752px"}
                        w={"100%"}
                        textAlign={"center"}
                      >
                        You{" "}
                        <Text fontWeight={500} as={"span"} color={"accent"}>
                          qualify
                        </Text>{" "}
                        for a {companyName} complimentary Super Performance
                        Review, and can view your results below.
                      </Text>
                    </Flex>
                    <Grid
                      templateColumns={{
                        base: "repeat(1, 1fr)",
                        tablet: "repeat(2, 1fr)",
                        laptop: "repeat(4, 1fr)",
                      }}
                      gap={{ base: 2, tablet: 4 }}
                    >
                      <GridItem
                        h={{ base: "66px", tablet: "88px" }}
                        borderRadius={"20px"}
                        bg={"white"}
                        p={{ base: 4, tablet: 6 }}
                        w="100%"
                        border={"2px solid #E5EBF5"}
                      >
                        <Flex
                          h={{ base: 8, tablet: "fit-content" }}
                          alignItems={"center"}
                          w={"100%"}
                          justifyContent={"space-between"}
                        >
                          <Flex
                            gap={{ base: 2, tablet: 3 }}
                            alignItems={"center"}
                            h={{ base: 8, tablet: 10 }}
                          >
                            <Box
                              bg={"accent"}
                              w={1}
                              borderRadius={"4px"}
                              height={{ base: 8, tablet: "100%" }}
                            />
                            <Text
                              fontSize={{ base: "12px", tablet: "14px" }}
                              lineHeight={{ base: 4, tablet: 5 }}
                              letterSpacing={"-0.01em"}
                              color={"grey"}
                              maxW={"139px"}
                            >
                              Approximate retirement balance
                            </Text>
                          </Flex>
                          <Text
                            color={"dark"}
                            fontSize={{ base: "20px", tablet: "24px" }}
                            lineHeight={{ base: "34px", tablet: 7 }}
                          >
                            $
                            {divideNumberByGrades(
                              Math.trunc(
                                calculationData.approximate_retirement_balance
                              )
                            )}
                          </Text>
                        </Flex>
                      </GridItem>

                      <GridItem
                        h={{ base: "66px", tablet: "88px" }}
                        borderRadius={"20px"}
                        bg={"white"}
                        p={{ base: 4, tablet: 6 }}
                        w="100%"
                        border={"2px solid #E5EBF5"}
                      >
                        <Flex
                          h={{ base: 8, tablet: "fit-content" }}
                          alignItems={"center"}
                          w={"100%"}
                          justifyContent={"space-between"}
                        >
                          <Flex
                            gap={{ base: 2, tablet: 3 }}
                            alignItems={"center"}
                            h={{ base: 8, tablet: 10 }}
                          >
                            <Box
                              bg={"#EDA52F"}
                              w={1}
                              borderRadius={"4px"}
                              height={{ base: 8, tablet: "100%" }}
                            />
                            <Text
                              fontSize={{ base: "12px", tablet: "14px" }}
                              lineHeight={{ base: 4, tablet: 5 }}
                              letterSpacing={"-0.01em"}
                              color={"grey"}
                              maxW={"139px"}
                            >
                              Your ideal <br />
                              retirement balance
                            </Text>
                          </Flex>
                          <Text
                            color={"dark"}
                            fontSize={{ base: "20px", tablet: "24px" }}
                            lineHeight={{ base: "34px", tablet: 7 }}
                          >
                            $
                            {divideNumberByGrades(
                              Math.trunc(
                                calculationData.your_ideal_retirement_balance
                              )
                            )}
                          </Text>
                        </Flex>
                      </GridItem>

                      <GridItem
                        h={{ base: "66px", tablet: "88px" }}
                        borderRadius={"20px"}
                        bg={"white"}
                        p={{ base: 4, tablet: 6 }}
                        w="100%"
                        border={"2px solid #E5EBF5"}
                      >
                        <Flex
                          w={"100%"}
                          h={{ base: 8, tablet: "fit-content" }}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Flex
                            gap={{ base: 2, tablet: 3 }}
                            alignItems={"center"}
                            h={{ base: 8, tablet: 10 }}
                          >
                            <Box
                              bg={"negative"}
                              w={1}
                              borderRadius={"4px"}
                              height={{ base: 8, tablet: "100%" }}
                            />
                            <Text
                              fontSize={{ base: "12px", tablet: "14px" }}
                              lineHeight={{ base: 4, tablet: 5 }}
                              letterSpacing={"-0.01em"}
                              color={"grey"}
                              maxW={"139px"}
                            >
                              Estimated retirement shortfall
                            </Text>
                          </Flex>
                          <Text
                            color={"dark"}
                            fontSize={{ base: "20px", tablet: "24px" }}
                            lineHeight={{ base: "34px", tablet: 7 }}
                          >
                            $
                            {divideNumberByGrades(
                              Math.trunc(
                                calculationData.estimated_retirement_shortfall
                              )
                            )}
                          </Text>
                        </Flex>
                      </GridItem>

                      <GridItem
                        h={{ base: "66px", tablet: "88px" }}
                        borderRadius={"20px"}
                        bg={"white"}
                        p={{ base: 4, tablet: 6 }}
                        w="100%"
                        border={"2px solid #00A023"}
                      >
                        <Flex
                          h={{ base: 8, tablet: "fit-content" }}
                          alignItems={"center"}
                          w={"100%"}
                          justifyContent={"space-between"}
                        >
                          <Flex
                            gap={{ base: 2, tablet: 3 }}
                            alignItems={"center"}
                            h={{ base: 8, tablet: 10 }}
                          >
                            <Box
                              bg={"success"}
                              w={1}
                              borderRadius={"4px"}
                              height={{ base: 8, tablet: "100%" }}
                            />
                            <Text
                              fontSize={{ base: "12px", tablet: "14px" }}
                              lineHeight={{ base: 4, tablet: 5 }}
                              letterSpacing={"-0.01em"}
                              color={"grey"}
                              maxW={"163px"}
                            >
                              Estimated alternative fund retirement balance
                            </Text>
                          </Flex>
                          <Text
                            color={"dark"}
                            fontSize={{ base: "20px", tablet: "24px" }}
                            lineHeight={{ base: "34px", tablet: 7 }}
                          >
                            $
                            {divideNumberByGrades(
                              Math.trunc(
                                calculationData.estimated_alternative_fund_retirement_balance
                              )
                            )}
                          </Text>
                        </Flex>
                      </GridItem>
                    </Grid>
                    <Chart
                      labels={Object.keys(
                        calculationData.by_years
                          .estimated_alternative_fund_retirement_balance
                      ).map((val) => val.replace("y_", ""))}
                      datasets={[
                        {
                          label: "Approximate Retirement Balance",
                          data: Object.values(
                            calculationData.by_years
                              .approximate_retirement_balance
                          ),
                          borderColor: "#247fd3",
                          backgroundColor: (context: {
                            chart: { chartArea: any; ctx: any; data?: unknown };
                          }) => {
                            const bgColor = [
                              "rgba(34, 126, 211, 1)",
                              "rgba(255, 255, 255, 0)",
                            ];
                            if (!context.chart.chartArea) {
                              return;
                            }
                            const {
                              ctx,
                              chartArea: { top, bottom },
                            } = context.chart;
                            const gradientBg = ctx.createLinearGradient(
                              0,
                              top,
                              0,
                              bottom
                            );
                            gradientBg.addColorStop(0, bgColor[0]);
                            gradientBg.addColorStop(1, bgColor[1]);
                            return gradientBg;
                          },

                          fill: true,
                          pointBackgroundColor: "#fff",
                          pointRadius: Object.values(
                            calculationData.by_years
                              .approximate_retirement_balance
                          ).map((_, index) => {
                            if (width <= 834) {
                              if (
                                index ===
                                Object.values(
                                  calculationData.by_years
                                    .approximate_retirement_balance
                                ).length -
                                  1
                              ) {
                                return 4;
                              } else {
                                return 0;
                              }
                            } else {
                              return 4;
                            }
                          }),
                        },
                        {
                          label:
                            "Alternative Fund Potential Retirement Balance",
                          data: Object.values(
                            calculationData.by_years
                              .estimated_alternative_fund_retirement_balance
                          ),
                          borderColor: "#00A023",

                          backgroundColor: (context: {
                            chart: { chartArea: any; ctx: any; data?: unknown };
                          }) => {
                            const bgColor = [
                              "rgba(0, 160, 35, 0.3)",
                              "rgba(255, 255, 255, 0)",
                            ];
                            if (!context.chart.chartArea) {
                              return;
                            }
                            const {
                              ctx,
                              chartArea: { top, bottom },
                            } = context.chart;
                            const gradientBg = ctx.createLinearGradient(
                              0,
                              top,
                              0,
                              bottom
                            );
                            gradientBg.addColorStop(0, bgColor[0]);
                            gradientBg.addColorStop(1, bgColor[1]);
                            return gradientBg;
                          },

                          fill: true,
                          pointBackgroundColor: "#fff",
                          pointRadius: Object.values(
                            calculationData.by_years
                              .estimated_alternative_fund_retirement_balance
                          ).map((_, index) => {
                            if (width <= 834) {
                              if (
                                index ===
                                Object.values(
                                  calculationData.by_years
                                    .estimated_alternative_fund_retirement_balance
                                ).length -
                                  1
                              ) {
                                return 4;
                              } else {
                                return 0;
                              }
                            } else {
                              return 4;
                            }
                          }),
                        },
                      ]}
                      legend={[
                        {
                          title: "Approximate Retirement Balance",
                          color: "#227ED3",
                        },
                        {
                          title:
                            "Estimated alternative fund retirement balance",
                          color: "#00A023",
                        },
                      ]}
                    />
                    <Flex
                      textAlign={"center"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={{ base: 6, tablet: 10 }}
                      my={{ base: 10, xl: 20 }}
                      color={"dark"}
                    >
                      <Box
                        h={1}
                        w={"200px"}
                        borderRadius={"4px"}
                        bg={
                          "linear-gradient(79.32deg, #00509A 0.83%, #227ED3 52.24%)"
                        }
                      />
                      <Heading
                        fontWeight={500}
                        maxW={"1008px"}
                        w={"100%"}
                        size={{ base: "sm", xl: "md" }}
                        as="h2"
                      >
                        {companyName} can show how you may be able to target{" "}
                        <Text as="span" textDecoration={"underline"}>
                          Alternative Funds
                        </Text>{" "}
                        with returns as high as 10% per year
                      </Heading>
                      <Text
                        color={"#808D9E"}
                        fontSize={{ base: "18px", xl: "20px" }}
                        lineHeight={{ base: "25.2px", xl: "32px" }}
                      >
                        This could result in a potential retirement balance of{" "}
                        <Text color={"dark"} as={"span"}>
                          $
                          {divideNumberByGrades(
                            Math.trunc(
                              Number(
                                calculationData.estimated_alternative_fund_retirement_balance
                              )
                            )
                          )}
                          ,
                        </Text>{" "}
                        <br />
                        with a weekly retirement income of{" "}
                        <Text color={"dark"} as={"span"}>
                          $
                          {divideNumberByGrades(
                            Math.trunc(
                              Number(
                                calculationData.alternative_fund_weekly_retirement_income
                              )
                            )
                          )}
                          .
                        </Text>
                      </Text>
                      <Text
                        maxW={"600px"}
                        w={"100%"}
                        fontSize={{ base: "18px", xl: "20px" }}
                        lineHeight={{ base: "25.2px", xl: "32px" }}
                      >
                        {companyName} will also provide an overview of your
                        current super fund, their fees, insurances and
                        performance against some of the top performing funds in
                        the country, explain the difference between wholesale,
                        SMSF & retail funds and most importantly show you
                        retirement projections.
                      </Text>
                    </Flex>

                    <Box pt={20} as="section">
                      <Heading textAlign={"center"} size={"md"} as={"h3"}>
                        Our happy clients
                      </Heading>
                    </Box>
                  </>
                ) : (
                  <>
                    <Flex flexDirection={"column"} alignItems={"center"}>
                      <CompanyTitle maxW="160px" />
                      <Heading
                        textAlign={"center"}
                        mt={{ base: 6, tablet: 10 }}
                        mb={{ base: "14px", tablet: 6 }}
                        size={{ base: "mobile", tablet: "md", xl: "lg" }}
                      >
                        As promised, here are your results
                      </Heading>
                      <Button
                        mt={6}
                        mb={"46px"}
                        w={"226px"}
                        rightIcon={<RestartIcon />}
                        variant={"primary"}
                        size={{ base: "mobile", tablet: "md" }}
                        onClick={() => {
                          sessionStorage.clear();
                          navigate("/");
                        }}
                      >
                        Restart Quiz
                      </Button>
                      <Grid
                        gap={{ base: 2, xl: 4 }}
                        w={"100%"}
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          xl: "repeat(3, 1fr)",
                        }}
                      >
                        <GridItem
                          h={"88px"}
                          borderRadius={"20px"}
                          bg={"white"}
                          p={6}
                          w="100%"
                          border={"2px solid #E5EBF5"}
                        >
                          <Flex w={"100%"} justifyContent={"space-between"}>
                            <Flex alignItems={"center"} gap={3}>
                              <Box
                                bg={"accent"}
                                w={1}
                                borderRadius={"4px"}
                                height={"100%"}
                              />
                              <Text
                                fontSize={"14px"}
                                lineHeight={5}
                                letterSpacing={"-0.01em"}
                                color={"grey"}
                                maxW={"139px"}
                              >
                                Approximate retirement balance
                              </Text>
                            </Flex>
                            <Text
                              color={"dark"}
                              fontSize={"24px"}
                              lineHeight={"34px"}
                            >
                              $
                              {divideNumberByGrades(
                                Math.trunc(
                                  calculationData.approximate_retirement_balance
                                )
                              )}
                            </Text>
                          </Flex>
                        </GridItem>

                        <GridItem
                          h={"88px"}
                          borderRadius={"20px"}
                          bg={"white"}
                          p={6}
                          w="100%"
                          border={"2px solid #E5EBF5"}
                        >
                          <Flex w={"100%"} justifyContent={"space-between"}>
                            <Flex alignItems={"center"} gap={3}>
                              <Box
                                bg={"#EDA52F"}
                                w={1}
                                borderRadius={"4px"}
                                height={"100%"}
                              />
                              <Text
                                fontSize={"14px"}
                                lineHeight={5}
                                letterSpacing={"-0.01em"}
                                color={"grey"}
                                maxW={"139px"}
                              >
                                Your ideal <br />
                                retirement balance
                              </Text>
                            </Flex>
                            <Text
                              color={"dark"}
                              fontSize={"24px"}
                              lineHeight={"34px"}
                            >
                              $
                              {divideNumberByGrades(
                                Math.trunc(
                                  calculationData.your_ideal_retirement_balance
                                )
                              )}
                            </Text>
                          </Flex>
                        </GridItem>

                        <GridItem
                          h={"88px"}
                          borderRadius={"20px"}
                          bg={"white"}
                          p={6}
                          w="100%"
                          border={"2px solid #E5EBF5"}
                        >
                          <Flex w={"100%"} justifyContent={"space-between"}>
                            <Flex alignItems={"center"} gap={3}>
                              <Box
                                bg={"negative"}
                                w={1}
                                borderRadius={"4px"}
                                height={"100%"}
                              />
                              <Text
                                fontSize={"14px"}
                                lineHeight={5}
                                letterSpacing={"-0.01em"}
                                color={"grey"}
                                maxW={"139px"}
                              >
                                Estimated retirement shortfall
                              </Text>
                            </Flex>
                            <Text
                              color={"dark"}
                              fontSize={"24px"}
                              lineHeight={"34px"}
                            >
                              $
                              {divideNumberByGrades(
                                Math.trunc(
                                  calculationData.estimated_retirement_shortfall
                                )
                              )}
                            </Text>
                          </Flex>
                        </GridItem>
                      </Grid>
                      <Box width={"100%"}>
                        <Chart
                          legend={[
                            {
                              title: "Approximate Retirement Balance",
                              color: "#227ED3",
                            },
                          ]}
                          datasets={[
                            {
                              label: "Approximate Retirement Balance",
                              data: Object.values(
                                calculationData.by_years
                                  .approximate_retirement_balance
                              ),
                              borderColor: "#247fd3",
                              backgroundColor: (context: {
                                chart: {
                                  chartArea: any;
                                  ctx: any;
                                  data?: unknown;
                                };
                              }) => {
                                const bgColor = [
                                  "rgba(34, 126, 211, 0.3)",
                                  "rgba(255, 255, 255, 0)",
                                ];
                                if (!context.chart.chartArea) {
                                  return;
                                }
                                const {
                                  ctx,
                                  chartArea: { top, bottom },
                                } = context.chart;
                                const gradientBg = ctx.createLinearGradient(
                                  0,
                                  top,
                                  0,
                                  bottom
                                );
                                gradientBg.addColorStop(0, bgColor[0]);
                                gradientBg.addColorStop(1, bgColor[1]);
                                return gradientBg;
                              },

                              fill: true,
                              pointBackgroundColor: "#fff",
                              pointRadius: Object.values(
                                calculationData.by_years
                                  .approximate_retirement_balance
                              ).map((_, index) => {
                                if (width <= 834) {
                                  if (
                                    index ===
                                    Object.values(
                                      calculationData.by_years
                                        .approximate_retirement_balance
                                    ).length -
                                      1
                                  ) {
                                    return 4;
                                  } else {
                                    return 0;
                                  }
                                } else {
                                  return 4;
                                }
                              }),
                            },
                          ]}
                          labels={Object.keys(
                            calculationData.by_years
                              .estimated_alternative_fund_retirement_balance
                          ).map((val) => val.replace("y_", ""))}
                        />
                      </Box>
                    </Flex>
                  </>
                )}
              </Container>
            </Box>
          </Box>
          {calculationData.is_qualified && <Slider />}
          <Footer />
        </>
      )}
    </>
  );
};

export default ResultPage;
