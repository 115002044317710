import { FC } from "react";

interface RangeIconProps {
	rotate?: boolean;
}

export const RangeIcon: FC<RangeIconProps> = ({ rotate }) => {
	return (
		<svg
			className={rotate ? "rotate-180" : ""}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 19L9 12L15 5"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
