export const SEVENTH_STEP_OPTIONS = [
	{ title: "500" },
	{ title: "1000" },
	{ title: "1500" },
	{ title: "2000" },
	{ title: "3000" },
	{ title: "4000" },
	{ title: "5000" },
	{ title: "6000" },
	{ title: "9000" },
	{ title: "Get My Results" },
];
