import { FC } from "react";

interface ArrowSliderIconProps {
	rotate?: boolean;
}

const ArrowSliderIcon: FC<ArrowSliderIconProps> = ({ rotate }) => {
	return (
		<svg
			className={rotate ? "rotate-180" : ""}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.2">
				<path
					d="M16.0013 29.3332C8.6375 29.3332 2.66797 23.3636 2.66797 15.9998C2.66797 8.63604 8.6375 2.6665 16.0013 2.6665C23.3651 2.6665 29.3346 8.63604 29.3346 15.9998C29.3346 23.3636 23.3651 29.3332 16.0013 29.3332Z"
					fill="#001C3D"
				/>
				<path
					d="M16.0013 29.3332C8.6375 29.3332 2.66797 23.3636 2.66797 15.9998C2.66797 8.63604 8.6375 2.6665 16.0013 2.6665C23.3651 2.6665 29.3346 8.63604 29.3346 15.9998C29.3346 23.3636 23.3651 29.3332 16.0013 29.3332Z"
					fill="black"
					fillOpacity="0.2"
				/>
			</g>
			<path
				d="M17.2929 11.2929C17.6834 10.9024 18.3166 10.9024 18.7071 11.2929C19.0976 11.6834 19.0976 12.3166 18.7071 12.7071L15.4142 16L18.7071 19.2929C19.0976 19.6834 19.0976 20.3166 18.7071 20.7071C18.3166 21.0976 17.6834 21.0976 17.2929 20.7071L13.2929 16.7071C12.9024 16.3166 12.9024 15.6834 13.2929 15.2929L17.2929 11.2929Z"
				fill="#001C3D"
			/>
			<path
				d="M17.2929 11.2929C17.6834 10.9024 18.3166 10.9024 18.7071 11.2929C19.0976 11.6834 19.0976 12.3166 18.7071 12.7071L15.4142 16L18.7071 19.2929C19.0976 19.6834 19.0976 20.3166 18.7071 20.7071C18.3166 21.0976 17.6834 21.0976 17.2929 20.7071L13.2929 16.7071C12.9024 16.3166 12.9024 15.6834 13.2929 15.2929L17.2929 11.2929Z"
				fill="black"
				fillOpacity="0.2"
			/>
		</svg>
	);
};

export default ArrowSliderIcon;
