import { useEffect, useState } from "react";

export const useTimer = (
  limit: number,
  restart: boolean = false,
  finishCallback?: () => void
) => {
  const [seconds, setSeconds] = useState(limit);

  useEffect(() => {
    setSeconds(limit);
    if (restart) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            finishCallback && finishCallback();
            return limit;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [restart]);

  return seconds;
};
