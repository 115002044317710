const UserHandUpIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.81828 5.27233C2.22012 5.17186 2.62732 5.41619 2.72778 5.81803L3.10975 7.3459C3.71957 9.78517 5.64125 11.6764 8.0847 12.2499H16.0002C18.0912 12.2499 19.8512 13.815 20.0956 15.8918L20.745 21.4123C20.7934 21.8237 20.4992 22.1964 20.0878 22.2448C19.6764 22.2932 19.3037 21.9989 19.2553 21.5876L18.6058 16.067C18.4504 14.7457 17.3306 13.7499 16.0002 13.7499H7.91785L7.83748 13.7321C4.80227 13.0576 2.40864 10.7261 1.65454 7.7097L1.27257 6.18183C1.17211 5.77999 1.41643 5.37279 1.81828 5.27233Z"
				fill="#001C3D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.81828 5.27233C2.22012 5.17186 2.62732 5.41619 2.72778 5.81803L3.10975 7.3459C3.71957 9.78517 5.64125 11.6764 8.0847 12.2499H16.0002C18.0912 12.2499 19.8512 13.815 20.0956 15.8918L20.745 21.4123C20.7934 21.8237 20.4992 22.1964 20.0878 22.2448C19.6764 22.2932 19.3037 21.9989 19.2553 21.5876L18.6058 16.067C18.4504 14.7457 17.3306 13.7499 16.0002 13.7499H7.91785L7.83748 13.7321C4.80227 13.0576 2.40864 10.7261 1.65454 7.7097L1.27257 6.18183C1.17211 5.77999 1.41643 5.37279 1.81828 5.27233Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<g opacity="0.2">
				<path
					d="M8 13.75V18C8 19.8856 8 20.8284 8.58579 21.4142C9.17157 22 10.1144 22 12 22C13.8856 22 14.8284 22 15.4142 21.4142C16 20.8284 16 19.8856 16 18V13.75H8Z"
					fill="#001C3D"
				/>
				<path
					d="M8 13.75V18C8 19.8856 8 20.8284 8.58579 21.4142C9.17157 22 10.1144 22 12 22C13.8856 22 14.8284 22 15.4142 21.4142C16 20.8284 16 19.8856 16 18V13.75H8Z"
					fill="black"
					fillOpacity="0.2"
				/>
			</g>
			<circle cx="12" cy="6" r="4" fill="#001C3D" />
			<circle cx="12" cy="6" r="4" fill="black" fillOpacity="0.2" />
		</svg>
	);
};

export default UserHandUpIcon;
