export const getCompanyNameByDomain = () => {
  const hostname = window.location.hostname

  switch (hostname) {
    case "iwg.superstrategyreview.com.au":
      return "Integral Wealth Group";
    case "cfp.superstrategyreview.com.au":
      return "Capital Financial Planning";
    default:
      return "Capital Financial Planning";
  }
};
