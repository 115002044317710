/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Text,
	Flex,
} from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { Controller, Control } from "react-hook-form";
import { FormValues } from "../../pages/resultPage/ResultPage";
import InputMask from "react-input-mask";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

interface InputProps {
	label: string;
	name: keyof FormValues;
	control: Control<FormValues> | any;
	maxLength?: number;
	trigger: () => void;
	mask?: string;
	size?: number;
	error?: string; 
	mbError?: string | {[key: string]: string | number}
}

const CustomInput: FC<InputProps> = ({
	label,
	name,
	control,
	mask = "",
	size,
	maxLength,
	trigger,
	error,
	mbError = ""
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef(null);

	return (
		<FormControl isInvalid={!!error}>
			<Flex
				h={{ base: "64px", tablet: 20 }}
				position={"relative"}
				border={`2px solid ${
					error ? "#D21212" : isFocused ? "#227ED3" : "#E5EBF5"
				}`}
				borderRadius={"20px"}
				overflow={"hidden"}
				alignItems={"flex-end"}
			>
				<Controller
					rules={{ required: true }}
					control={control}
					render={({ field }) => (
						<>
							<FormLabel
								display={"flex"}
								alignItems={"flex-end"}
								position={"relative"}
								h={"100%"}
								w={"100%"}
								px={{ base: 6, tablet: 8 }}
								py={{ base: "10px", tablet: "13px" }}
								htmlFor={name}
								m={0}
							>
								<Text
									transition={"0.3s"}
									top={isFocused || field.value ? { base: 4, tablet: 6 } : "50%"}
									transform={"translateY(-50%)"}
									position={"absolute"}
									left={"auto"}
									color={"grey"}
									fontSize={
										isFocused || field.value
											? { base: "12px", tablet: "14px" }
											: { base: "18px", tablet: "20px" }
									}
									lineHeight={5}
									fontWeight={400}
									letterSpacing={"-0.02em"}
								>
									{label}
								</Text>

								<InputMask
									{...field}
									onFocus={() => setIsFocused(true)}
									ref={ref}
									onBlur={() => {
										setIsFocused(false);
										trigger();
									}}
									maskPlaceholder={""}
									mask={mask}
								>
									<input
										className="input"
										size={size}
										maxLength={maxLength}
										style={{
											outline: "none",
											color: "#001C3D",
											width: "100%",
										}}
										id={name}
									/>
								</InputMask>
							</FormLabel>
						</>
					)}
					name={name}
				/>
			</Flex>

			{!!error && (
				<FormErrorMessage
					fontSize={{base: "12px", tablet: "14px"}}
					lineHeight={5}
					letterSpacing={"-0.01em"}
					color={"negative"}
					mb={mbError}
				>
					{" "}
					{error}{" "}
				</FormErrorMessage>
			)}
		</FormControl>
	);
};

export default CustomInput;
