const RestartIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle opacity="0.2" cx="16.0013" cy="16" r="13.3333" fill="white" />
			<path
				d="M21.8676 8.3815C21.8676 7.97491 21.6214 7.60879 21.2449 7.4554C20.8683 7.302 20.4364 7.39187 20.1523 7.68273L19.1516 8.70712C16.3241 7.40617 12.886 7.94536 10.5693 10.317C7.58627 13.3708 7.58627 18.3124 10.5693 21.3662C13.5651 24.4331 18.4323 24.4331 21.4281 21.3662C23.1659 19.5872 23.8877 17.1705 23.606 14.8676C23.539 14.3194 23.0402 13.9293 22.492 13.9964C21.9438 14.0634 21.5538 14.5622 21.6208 15.1104C21.8329 16.8446 21.2877 18.6478 19.9974 19.9686C17.7862 22.2322 14.2112 22.2322 12 19.9686C9.77604 17.692 9.77604 13.9913 12 11.7146C13.5277 10.1507 15.7047 9.66853 17.632 10.2627L16.6167 11.3021C16.3356 11.5899 16.2543 12.0181 16.4104 12.3889C16.5666 12.7597 16.9297 13.0009 17.3321 13.0009H20.8676C21.4199 13.0009 21.8676 12.5531 21.8676 12.0009V8.3815Z"
				fill="white"
			/>
		</svg>
	);
};

export default RestartIcon;
