import { Container, Flex, Heading, Box, Text } from "@chakra-ui/react";

import { CompanyTitle } from "../components/companyTitle/CompanyTitle";
import { Footer } from "../components/footer/Footer";
import { ReactNode } from "react";

const LawPagesLayout = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode | ReactNode[];
}) => {
  return (
    <Box bg={"blue"} as="main">
      <Flex
        h={{ base: "56px", tablet: 20 }}
        borderBottom={"2px solid"}
        borderColor={"border"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CompanyTitle maxW="170px" />
      </Flex>
      <Box
        px={{ base: "16px", tablet: "40px", xl: "80px" }}
        bg={"blue"}
        as="section"
      >
        <Container
          px={0}
          pt={{ base: 4, tablet: 8 }}
          pb={{ base: 10, xl: 20 }}
          maxW={"1008px"}
        >
          <Flex flexDirection="column" alignItems={"center"}>
            <Heading
              textAlign={"center"}
              mb={{ base: 6, xl: 10 }}
              size={{ base: "mobile", xl: "lg" }}
            >
              {title}
            </Heading>
            <Flex
              letterSpacing={"-0.01em"}
              color={"dark"}
              fontSize={{ base: "18px", xl: "20px" }}
              lineHeight={{ base: "25.2px", xl: 7 }}
              flexDirection="column"
              gap={{ base: 4, xl: 6 }}
            >
              {children}
              <Text color={"grey"}>Updated 10/10/2023</Text>
            </Flex>
          </Flex>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default LawPagesLayout;
