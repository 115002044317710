import { Box } from "@chakra-ui/react";
import { FC } from "react";
import Logo from "../../assets/icons/Logo";

interface CompanyTitleProps {
  maxW?: string;
}

export const CompanyTitle: FC<CompanyTitleProps> = ({ maxW }) => {
  return (
    <Box
	  cursor={"pointer"}
      onClick={() => location.reload()}
      maxW={{ base: "200px", tablet: "267px" }}
    >
      <Logo />
    </Box>
  );
};
