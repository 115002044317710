import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { FC, useEffect, useState } from "react";

const MINIMAL_ACCESS_AGE = 17;
const MAXIMUM_ACCESS_AGE = 67;

interface FourthStepProps {
	setCurrentStep: () => void;
	isAgeValid: boolean;
	setIsAgeValid: (val: boolean) => void;
	age: string;
	setAge: (val: string) => void;
}

export const FourthStep: FC<FourthStepProps> = ({
	setCurrentStep,
	isAgeValid,
	setIsAgeValid,
	age,
	setAge,
}) => {
	const checkAgeValidation = (age: string) => {
		if (+age > MINIMAL_ACCESS_AGE && +age <= MAXIMUM_ACCESS_AGE) {
			setIsAgeValid(true);
		} else {
			setIsAgeValid(false);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Use a regular expression to remove numeric characters
		const sanitizedValue = inputValue.replace(/\D/g, "");
		setAge(sanitizedValue);
	};

	useEffect(() => {
		if (age) {
			checkAgeValidation(age);
		}
	}, [age]);

	return (
		<Flex
			flexDirection={{ base: "column", tablet: "row" }}
			mb={{ base: "0", xl: "76px" }}
			w={"100%"}
			justifyContent={"center"}
			gap={4}
		>
			<Box position={"relative"}>
				<Input
					value={age}
					onChange={(e) => {
						sessionStorage.setItem("age", e.target.value);
						handleInputChange(e);
						checkAgeValidation(e.target.value);
					}}
					maxW={{ base: "100%", tablet: "204px" }}
					maxLength={3}
					border={
						!isAgeValid ? "2px solid #D21212!important" : "2px solid #E5EBF5"
					}
					w={"100%"}
					placeholder="Enter your age"
				/>
				{!isAgeValid && (
					<Text
						position={{ base: "relative", tablet: "absolute" }}
						mt={{ base: "10px", tablet: "0" }}
						top={"calc(100% + 10px)"}
						left={0}
						w={"100%"}
						textAlign={{ base: "left", tablet: "center" }}
						color={"negative"}
						size={"sm"}
					>
						Required age range 18-67
					</Text>
				)}
			</Box>

			<Box w={{ base: "100%", tablet: "fit-content" }}>
				<PrimaryButton
					width="100%"
					isDisabled={!age || !isAgeValid}
					innerText="Next"
					tooltipVisible={!!age && isAgeValid}
					onClick={setCurrentStep}
				/>
			</Box>
		</Flex>
	);
};
