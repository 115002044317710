import React from "react";

const ArrowLeft = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path
          d="M16.5003 29.3337C23.8641 29.3337 29.8337 23.3641 29.8337 16.0003C29.8337 8.63653 23.8641 2.66699 16.5003 2.66699C9.13653 2.66699 3.16699 8.63653 3.16699 16.0003C3.16699 23.3641 9.13653 29.3337 16.5003 29.3337Z"
          fill="#001C3D"/>
        <path
          d="M16.5003 29.3337C23.8641 29.3337 29.8337 23.3641 29.8337 16.0003C29.8337 8.63653 23.8641 2.66699 16.5003 2.66699C9.13653 2.66699 3.16699 8.63653 3.16699 16.0003C3.16699 23.3641 9.13653 29.3337 16.5003 29.3337Z"
          fill="black" fillOpacity="0.2"/>
      </g>
      <path
        d="M14.4599 20.7071C14.8504 21.0976 15.4836 21.0976 15.8741 20.7071C16.2646 20.3166 16.2646 19.6834 15.8741 19.2929L13.5812 17L21.8336 17C22.3859 17 22.8336 16.5523 22.8336 16C22.8336 15.4477 22.3859 15 21.8336 15L13.5812 15L15.8741 12.7071C16.2646 12.3166 16.2646 11.6834 15.8741 11.2929C15.4836 10.9024 14.8504 10.9024 14.4599 11.2929L10.4599 15.2929C10.0694 15.6834 10.0694 16.3166 10.4599 16.7071L14.4599 20.7071Z"
        fill="#001C3D"/>
      <path
        d="M14.4599 20.7071C14.8504 21.0976 15.4836 21.0976 15.8741 20.7071C16.2646 20.3166 16.2646 19.6834 15.8741 19.2929L13.5812 17L21.8336 17C22.3859 17 22.8336 16.5523 22.8336 16C22.8336 15.4477 22.3859 15 21.8336 15L13.5812 15L15.8741 12.7071C16.2646 12.3166 16.2646 11.6834 15.8741 11.2929C15.4836 10.9024 14.8504 10.9024 14.4599 11.2929L10.4599 15.2929C10.0694 15.6834 10.0694 16.3166 10.4599 16.7071L14.4599 20.7071Z"
        fill="black" fillOpacity="0.2"/>
    </svg>
  );
};

export default ArrowLeft;
