import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { createArrayOfRate } from "../../utils/helpers/createArrayOfRate";
import StarIco from "../../assets/icons/StarIco";
import { useRef } from "react";
import { BorderedButton } from "../buttons/BorderedButton";
import ArrowSliderIcon from "../../assets/icons/ArrowSliderIcon";

const url = window.location.hostname;

const slides =
  url === "iwg.superstrategyreview.com.au"
    ? [
        {
          text: "Great people who always answer the phone, if you have left a message they always call back and have helped us understand our superannuation a lot better than we did.",
          rate: 5,
          author: "Amanda Sanita",
        },
        {
          text: "The overall experience was very informative. At no time did we feel obligated or pressured . As with all things new,there was some trepidation. But all questions were addressed and answered. All in all a pleasant experience.",
          rate: 5,
          author: "Greg Sezonenko",
        },
        {
          text: "Appears to be a very good way to get to your retirement goals. Just received the first income from the property trust. Very happy.  Very good financial products",
          rate: 5,
          author: "David Slezak",
        },
        {
          text: "Such professionalism exhibited from all the group. I couldn't be happier.  Thank you.",
          rate: 5,
          author: "Tamie Blanch",
        },
        {
          text: "Very happy with the service provided by Integral Wealth group. Always answering our questions within a quick time frame and have made the process for us simple and painless",
          rate: 5,
          author: "Brendan Fox",
        },
        {
          text: "Only just started with Integral so time will tell. Cheers to Adam for getting me started on this, the reviews are helpful. *UPDATE* so far so good, everything is running along smoothly.",
          rate: 5,
          author: "Troy Bourne",
        },
        {
          text: "JP and the team did a fantastic job in enlightening me about the bright and dark side of superannuation. Dare I say that it is a taboo topic for me and one that I tend to sweep under the rug. JP was very professional, persistent and influential in making me switch from my existing (now former) fund manager as I was not receiving any service. I am highly confident that Integral Wealth will look after my financial needs in the many years to come.",
          rate: 5,
          author: "Michael Amparo",
        },
        {
          text: "Couldn't be happier with what they have done for me and my super. Dealt with Steve, whose experience and knowledge in this area was second to none. I am now in a much better financial position with my super. I was never pushed into making a decision, the information and data was clearly explained to be so I could make informed decisions that best suit me. Thoroughly recommend Integral as financial professionals to deal with.",
          rate: 5,
          author: "Grant Lean",
        },
        {
          text: "Very positive interactions with staff and advice given with no pressure. Very impressed with the service.",
          rate: 5,
          author: "Christina Adams",
        },
        {
          text: "Great friendly team, easy to understand processes. You're in control but the team at Integral Wealth Group do all the hard work keeping you informed, ensuring the whole transition is seamless and stress free.",
          rate: 5,
          author: "Daniel Langridge",
        },
        {
          text: "Friendly, knowledgable and helpful people who have got my finances and insurances back to where they should be.  Thanks!",
          rate: 5,
          author: "Josh Hartwig",
        },
      ]
    : [
        {
          text: (
            <>
              <p>
                What an ease it has been dealing with Capital Financial Planning
                and what a contrast with other companies.
              </p>
              <p>
                We recently spent a whole weekend in a supposed masterclass with
                another company where we were only given a pile of advertising
                and projections based on our current banks doing the dirty on
                us. I was a total skeptic after that. But was pleasantly
                supposed.
              </p>
              <p>
                The capital finance team have given us honest and open advice.
                Their fees are reasonable and they have a great team who are
                always ready to assist. I am really looking forward to working
                with them.
              </p>
            </>
          ),
          rate: 5,
          author: "Drew W",
        },
        {
          text: "The team at Capital Financial Planning are very professional. They contacted me promptly and were very informative and thorough. Would recommend them in the future if you’re wanting help with your superannuation or finances.",
          rate: 5,
          author: "Amanda Crouch",
        },
        {
          text: "Very supportive, informative and helpful. Let’s see if the rollover is worth it.",
          rate: 5,
          author: "Charles Nielsen",
        },
        {
          text: "Really supportive and easy process to complete",
          rate: 5,
          author: "Jessica Tones",
        },
      ];

const Slider = () => {
  const swiperRef = useRef<SwiperType>();

  return (
    <Box bg={"blue"} pb={{ base: 10, tablet: 20 }}>
      <Container
        maxW={{ base: "100%", tablet: "90%", "3xl": "70%" }}
        pl={{ base: 4, tablet: 0 }}
        pr={{ base: 0 }}
      >
        <Swiper
          modules={[Navigation]}
          onBeforeInit={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
          loop={true}
          spaceBetween={10}
          slidesPerView={"auto"}
          breakpoints={{
            375: {
              slidesPerView: 1.1,
            },
            834: {
              slidesPerView: "auto",
              centeredSlides: true,
            },
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <Flex
                flexDirection={"column"}
                borderRadius={"20px"}
                p={{ base: "24px 16px", tablet: 10 }}
                border={"2px solid #E5EBF5"}
                minH={"580px"}
                h={"100%"}
                w={{ base: "100%", tablet: "496px" }}
                bg={"white"}
                color={"dark"}
                fontSize={{ base: "18px", tablet: "20px" }}
                lineHeight={{ base: "25.2px", tablet: 7 }}
                letterSpacing={"-0.02em"}
              >
                <Flex flex={"1 1 auto"} flexDirection={"column"} gap={2}>
                  {slide.text}
                </Flex>
                <Flex mb={2}>
                  {createArrayOfRate(slide.rate).map((star) => {
                    return (
                      <Box key={star}>
                        <StarIco />
                      </Box>
                    );
                  })}
                </Flex>
                <Text color={"grey"} size="sm">
                  {slide.author}
                </Text>
              </Flex>
            </SwiperSlide>
          ))}
          <Flex
            mt={{ base: 6, tablet: 10 }}
            gap={{ base: 2, tablet: 4 }}
            justifyContent={"center"}
          >
            <BorderedButton onClick={() => swiperRef.current?.slidePrev()}>
              <ArrowSliderIcon />
            </BorderedButton>
            <BorderedButton onClick={() => swiperRef.current?.slideNext()}>
              <ArrowSliderIcon rotate />
            </BorderedButton>
          </Flex>
        </Swiper>
      </Container>
    </Box>
  );
};

export default Slider;
