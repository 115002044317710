const BookAppointmentCalender = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 161 160"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M118.631 19.589H132.736C143.971 19.589 153.097 28.7156 153.097 39.9509V128.381C153.097 139.616 143.971 148.742 132.701 148.742H28.3005C17.0655 148.742 7.93898 139.616 7.93898 128.381C7.9043 50.4946 7.9043 39.9509 7.9043 39.9509C7.9043 28.7159 17.0652 19.5893 28.3005 19.5893H42.3705V14.715C42.3705 12.8137 43.9262 11.2581 45.8274 11.2581C47.7287 11.2581 49.2843 12.8137 49.2843 14.715V19.5893H111.718V14.715C111.718 12.8137 113.273 11.2581 115.175 11.2581C115.629 11.2565 116.079 11.3449 116.499 11.518C116.919 11.6912 117.301 11.9458 117.622 12.2671C117.944 12.5884 118.198 12.9701 118.372 13.3902C118.545 13.8103 118.633 14.2605 118.631 14.715V19.589ZM132.701 141.828C140.134 141.828 146.184 135.813 146.184 128.381L146.183 63.1815H14.8527V128.381C14.8527 135.813 20.868 141.828 28.3005 141.828H132.701Z"
				fill="#001C3D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M80.5355 73.4143C64.4609 73.4143 51.4277 86.4818 51.4277 102.522C51.4277 118.562 64.4605 131.63 80.5355 131.63C96.5759 131.63 109.643 118.562 109.643 102.522C109.643 86.4818 96.5759 73.4143 80.5355 73.4143ZM79.5331 110.612L90.8718 99.3071C92.2199 97.959 92.2199 95.7465 90.8718 94.3984C89.524 93.0503 87.3112 93.0503 85.9631 94.3984L77.113 103.283L75.0734 101.243C74.7536 100.923 74.3737 100.668 73.9555 100.495C73.5373 100.321 73.0889 100.232 72.6362 100.232C72.1834 100.232 71.7351 100.321 71.3169 100.495C70.8987 100.668 70.5188 100.923 70.199 101.243C69.8708 101.562 69.6099 101.944 69.4318 102.365C69.2537 102.787 69.1619 103.24 69.1619 103.698C69.1619 104.155 69.2537 104.608 69.4318 105.03C69.6099 105.452 69.8708 105.833 70.199 106.152L74.6584 110.612C75.3152 111.303 76.2143 111.614 77.113 111.614C77.9774 111.614 78.8762 111.303 79.5331 110.612Z"
				fill="#00A023"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M146.184 128.381C146.184 135.813 140.134 141.828 132.701 141.828H28.3005C20.868 141.828 14.8527 135.813 14.8527 128.381V63.1815H146.183L146.184 128.381ZM51.4277 102.522C51.4277 86.4818 64.4609 73.4143 80.5355 73.4143C96.5759 73.4143 109.643 86.4818 109.643 102.522C109.643 118.562 96.5759 131.63 80.5355 131.63C64.4605 131.63 51.4277 118.562 51.4277 102.522Z"
				fill="#E5EBF5"
			/>
		</svg>
	);
};

export default BookAppointmentCalender;
