import { FC } from "react";

interface StepIconProps {
	number: number;
	x?: number | null;
}

export const StepIcon: FC<StepIconProps> = ({ number, x }) => {
	return (
		<svg
			className="step-icon"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5345 2.54691C19.0639 1.68006 20.9361 1.68006 22.4655 2.54691L28.2 5.79722L33.8821 9.13827C35.3975 10.0293 36.3336 11.6507 36.3476 13.4086L36.4 20L36.3476 26.5914C36.3336 28.3493 35.3975 29.9707 33.8821 30.8617L28.2 34.2028L22.4655 37.4531C20.9361 38.3199 19.0639 38.3199 17.5345 37.4531L11.8 34.2028L6.11793 30.8617C4.60253 29.9707 3.6664 28.3493 3.65243 26.5914L3.60003 20L3.65243 13.4086C3.6664 11.6507 4.60253 10.0293 6.11793 9.13827L11.8 5.79721L17.5345 2.54691Z"
				stroke="#001C3D"
				strokeWidth="2"
			/>
			<path
				d="M17.5345 2.54691C19.0639 1.68006 20.9361 1.68006 22.4655 2.54691L28.2 5.79722L33.8821 9.13827C35.3975 10.0293 36.3336 11.6507 36.3476 13.4086L36.4 20L36.3476 26.5914C36.3336 28.3493 35.3975 29.9707 33.8821 30.8617L28.2 34.2028L22.4655 37.4531C20.9361 38.3199 19.0639 38.3199 17.5345 37.4531L11.8 34.2028L6.11793 30.8617C4.60253 29.9707 3.6664 28.3493 3.65243 26.5914L3.60003 20L3.65243 13.4086C3.6664 11.6507 4.60253 10.0293 6.11793 9.13827L11.8 5.79721L17.5345 2.54691Z"
				stroke="black"
				strokeOpacity="0.2"
				strokeWidth="2"
			/>
			<text x={x || "15"} y="27" fontFamily="Readex Pro" fontSize="20" fill="#001C3D">
				{number}
			</text>
		</svg>
	);
};
