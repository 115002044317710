import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { Footer } from "../../components/footer/Footer";
import { CompanyTitle } from "../../components/companyTitle/CompanyTitle";
import BookAppointmentCalender from "../../assets/icons/BookAppointmentCalender";
import UserHandUpIcon from "../../assets/icons/UserHandUpIcon";
import { format, parseISO } from "date-fns";

const SuccessBookingAppointment = () => {
	const firstName = sessionStorage.getItem("CONTACT_NAME");
	const lastName = sessionStorage.getItem("CONTACT_SURNAME");
	const appointmentDate =
		sessionStorage.getItem("BOOKING_APPOINTMENT_DATE") || "";
	return (
		<Box as="main">
			<Box
				px={{ base: "16px", tablet: "40px", xl: "80px" }}
				bg={"blue"}
				as="section"
			>
				<Container px={0} pt={10} pb={{ base: 10, xl: "80px" }} maxW={"857px"}>
					<Flex w={"100%"} flexDirection={"column"} alignItems={"center"}>
						<CompanyTitle />
						<Flex
							mt={{ base: 6, xl: 10 }}
							mb={{ base: 6, tablet: 10, xl: "60px" }}
							py={{ base: 8, tablet: 20, xl: "120px" }}
							px={{ base: 4, tablet: 20, xl: "120px" }}
							borderRadius={"20px"}
							w={"100%"}
							bg={"white"}
							flexDirection={"column"}
							alignItems={"center"}
							textAlign={"center"}
						>
							<Box maxW={{ base: "120px", tablet: "145px", xl: "160px" }}>
								<BookAppointmentCalender />
							</Box>

							<Heading
								color={"dark"}
								size={{ base: "sm", xl: "md" }}
								mt={{ base: 6, tablet: 10 }}
								mb={4}
							>
								Dear {firstName} {lastName}
							</Heading>
							<Text
								fontSize={{ base: "18px", xl: "20px" }}
								lineHeight={{ base: "25.2px", xl: 7 }}
								letterSpacing={"-0.02em"}
								mb={{ base: 8, lg: 20 }}
								color={"grey"}
								maxW={"537px"}
								w={"100%"}
							>
								Your Appointment has been successfully booked for
								<Text color={"dark"} as="span">
									{" "}
									{format(parseISO(appointmentDate), "EEEE, d MMMM")}
								</Text>{" "}
								at{" "}
								<Text color={"dark"} as="span">
									{format(parseISO(appointmentDate), "hh:mm a")}.
								</Text>
							</Text>
							<Text
								px={{ base: 4, tablet: 10 }}
								py={{ base: 4, tablet: 6 }}
								fontSize={"14px"}
								lineHeight={5}
								letterSpacing={"-0.02em"}
								borderRadius={"20px"}
								border={"1px solid border"}
								bg={"blue"}
								color={"dark"}
							>
								You will receive an SMS shortly confirming your appointment, you
								will also receive a calendar invite and an email with your
								Online Meeting link.
							</Text>
						</Flex>
					</Flex>
					<Flex justifyContent={"center"} alignItems={"center"} gap={2}>
						<UserHandUpIcon />
						<Text fontSize={"14px"} lineHeight={5} color={"grey"}>
							We look forward to meeting with you
						</Text>
					</Flex>
				</Container>
			</Box>

			<Footer />
		</Box>
	);
};

export default SuccessBookingAppointment;
