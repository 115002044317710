import { Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

import LawPagesLayout from "../LawPagesLayout";

const PrivacyPolicy = () => {
	return (
		<LawPagesLayout title="Privacy Policy">
			<Text>
				This Privacy Policy explains how superstrategyreview.com.au which is
				owned by AMBA Solutions Pty Ltd ABN 74 623 403 190 deals with privacy
				issues relating to personal information which is provided by a person or
				entity (&apos;User&apos;) who uses the superstrategyreview.com.au
				website.
			</Text>
			<Text>
				You can email Super Strategy Review at
				(hello@superstrategyreview.com.au) if you have any queries about your
				Personal Information, how we deal with your Personal Information or
				would like to amend or otherwise delete your Personal Information.
			</Text>
			<Text>
				We are bound by the Australian Privacy Principles contained in the
				Privacy Act 1988 (Cth) (subject to exemptions that apply to us under
				that Act). We may, from time to time, review and update this policy,
				including taking account of new or amended laws, new technology and/or
				changes to our operations. All personal information held by us will be
				governed by the most recently updated policy. Your privacy matters to
				us, so whether you are new to superstrategyreview.com.au or are a
				long-time user, please take the time to get to know our practices
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				This Privacy Policy will describe:
			</Heading>
			<Text>
				How superstrategyreview.com.au will collect and handle a User&apos;s
				personal information; <br /> The kinds of personal information that
				superstrategyreview.com.au will collect and store; <br /> The purpose
				for which superstrategyreview.com.au collect personal information;
				<br /> How the User can contact the data controller;
				<br /> The use of cookies and third-party access on the
				superstrategyreview.com.au Platform.
				<br /> The User&apos;s Agreement to this Privacy Policy
			</Text>
			<Text>
				By using the superstrategyreview.com.au Platform a User consents to the
				privacy practices as disclosed in this Privacy Policy noting that
				personal information about the User may be collected by
				superstrategyreview.com.au or its Associates and relevant third-parties
				in accordance with this Privacy Policy:
			</Text>
			<UnorderedList spacing={4}>
				<ListItem>
					To contact the User in response to a query or feedback from the User
					or due to the information provided by the User;
				</ListItem>
				<ListItem>
					To enable the passing of your personal information to a third party to
					provide the User with information;
				</ListItem>
				<ListItem>
					For additional marketing and re-marketing purposes including marketing
					from third parties;
				</ListItem>
				<ListItem>
					To provide the User with indicative projections, quotes or information
					in relation to goods or services;
				</ListItem>
				<ListItem>To verify a User&apos;s identity.</ListItem>
				<ListItem>
					For any other purpose at superstrategyreview.com.au’s sole discretion;
				</ListItem>
			</UnorderedList>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Personal Information
			</Heading>
			<Text>
				For the purposes of this Privacy Policy personal information includes
				all and any information that the User submits to
				superstrategyreview.com.au when the User enquires about or seeks a
				comparison, performance review or quote for a product or service, visits
				the superstrategyreview.com.au Platform, subscribes to an email list or
				contacts superstrategyreview.com.au by telephone, via mail, email or
				online, which includes but is not limited to the following information:
			</Text>
			<UnorderedList spacing={4}>
				<ListItem>The User&apos;s full name;</ListItem>
				<ListItem>
					The User&apos;s relevant information required for qualification,
					including but not limited to; superannuation balance amount, annual
					income, superannuation fund, fund type, ideal weekly retirement
					income, employment status or other qualifying criteria (which can
					change at our discretion);
				</ListItem>
				<ListItem>
					The User&apos;s telephone numbers and email address;
				</ListItem>
				<ListItem>The User&apos;s State or Territories;</ListItem>
				<ListItem>The User&apos;s age;</ListItem>
				<ListItem>Any additional personal identifiable information</ListItem>
				<ListItem>
					Information which the User enters into the superstrategyreview.com.au
					Platform including but not limited to photographs, images or written
					content. Second or Third party information that is identifiable or
					non-identifiable including information about your computer, device,
					and browser, potentially including your IP address, browser type, and
					other software or hardware information. If you access the Site from a
					mobile or other device, we may collect a unique device identifier
					assigned to that device (“UDID”), geolocation data, or other
					transactional information for that device.
				</ListItem>
			</UnorderedList>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Collecting the User&apos;s Personal Information
			</Heading>
			<Text>
				superstrategyreview.com.au will not collect personal information about a
				User without that User&apos;s consent and will only collect that
				personal information which a User voluntarily provides to
				superstrategyreview.com.au. superstrategyreview.com.au may also collect
				personal information about a User from another business that you have
				authorised to deal with us on your behalf, from other companies that
				provide comparison services or other third parties.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Protecting the User&apos;s Personal Information
			</Heading>
			<Text>
				superstrategyreview.com.au will take all reasonable steps to protect the
				User&apos;s personal information from loss, misuse, and unauthorised
				access. superstrategyreview.com.au will also destroy or de-identify any
				personal information which belongs to a User if that User requests that
				superstrategyreview.com.au do so.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Use of Personal Information
			</Heading>
			<Text>
				superstrategyreview.com.au will use the User&apos;s personal information for
				the primary purpose of providing the User with the service or offers in
				relation to the service. To provide the service to the User,
				superstrategyreview.com.au pass the User&apos;s personal information to
				relevant third party businesses to provide the user with a quote,
				review, comparison or the like, at the users discretion or request.
			</Text>
			<Text>
				We may send you direct marketing communications and information about
				products and services that we consider may be of interest to you. This
				may be in the form of other products or services or other comparison
				services. These communications may be sent in various forms, including
				telephone, mail, SMS or email, in accordance with applicable marketing
				laws, such as the Spam Act 2004 (Cth). If you indicate a preference for
				a method of communication, we will endeavor to use that method whenever
				practical to do so. In addition, at any time, you may opt-out of
				receiving marketing communications from us by contacting us (details
				below) or by using the opt-out facilities provided (e.g. an unsubscribe
				link), or by contacting us using the details below. We will then ensure
				that your name is removed from our mailing list and distribution to any
				third party.
			</Text>
			<Text>
				superstrategyreview.com.au will receive remuneration for providing
				User&apos;s personal information and data to relevant third party businesses
				or participating service providers. Other purposes for which the User&apos;s
				personal information may be used include, but are not limited to:
			</Text>
			<UnorderedList spacing={4}>
				<ListItem>
					Enhancing the security of both superstrategyreview.com.au and the
					User;
				</ListItem>
				<ListItem>
					Providing superstrategyreview.com.au business partners or
					participating suppliers with the Users personal information so that
					they may provide the User with information, quotes and offers about
					their products and services;
				</ListItem>
				<ListItem>
					Contacting the User in respect of any queries or complaints;
				</ListItem>
				<ListItem>
					For internal business management including accounting;
				</ListItem>
				<ListItem>
					To advise the User of new product offering or promotions by mail,
					phone, SMS, MMS or email;
				</ListItem>
				<ListItem>
					To advise the User about changes to the superstrategyreview.com.au
					Platform&apos;s terms and conditions;
				</ListItem>
				<ListItem>
					For legal proceedings if a dispute or claim arises as a result of the
					User&apos;s use of the superstrategyreview.com.au Platform;
				</ListItem>
			</UnorderedList>
			<Text>
				User&apos;s personal information may be used by superstrategyreview.com.au
				and the superstrategyreview.com.au Platform to make automated decisions
				or modelling including, but not limited to:
			</Text>
			<UnorderedList spacing={4}>
				<ListItem>
					Create data modelling for internal uses including commercial decisions
				</ListItem>
				<ListItem>
					Data models that are externally sold or provided to third parties,
				</ListItem>
				<ListItem>
					Create audience modelling for marketing and remarketing purposes.
				</ListItem>
			</UnorderedList>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Who we pass your details to (Third Parties)
			</Heading>
			<Text>
				superstrategyreview.com.au are not a Financial Services Provider and do
				not provide any form of financial advice. Superannuation is a Financial
				Services Product and therefore needs to be handled by an AFSL holder or
				licensed representative. For this reason your information is passed onto
				a Licensed Financial Adviser to provide you with a performance review of
				your superannuation. This may be a direct AFSL holder or licensed
				representative or could be an intermediary service in order to book an
				appointment or pre-qualify further on behalf of a Financial Adviser.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Rights of Usage
			</Heading>
			<Text>
				These rights to use your data extend to superstrategyreview.com.au for
				an indefinite period of time. Ownership and distribution of your
				personal information to 3rd parties will be at the discretion of
				superstrategyreview.com.au until you unsubscribe or opt-out.
			</Text>
			<Text>
				The User&apos;s personal information is hosted on servers located in
				Australia. (location available upon request to users)
			</Text>
			<Text>
				The Data Controller is superstrategyreview.com.au. The Data Controller
				can be contacted via email at: hello@superstrategyreview.com.au
			</Text>
			<Text>or</Text>
			<Text>Level 3, 360 Kent St, Sydney, NSW, 2000</Text>
			<Text>Use of personal information for research purposes</Text>
			<Text>
				superstrategyreview.com.au may use a User&apos;s de-identified personal
				information for research and development purposes without obtaining a
				User&apos;s consent.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Individuals Right of Access
			</Heading>
			<Text>
				The User&apos;s personal information is collected in accordance with the
				Privacy Act 1988 (Cth) and is held for seven (7) years.
			</Text>
			<Text>
				A User can request to delete or amend any personal information stored by
				superstrategyreview.com.au or its Associates, and/or request a Subject
				Access Request (“SAR”) by contacting superstrategyreview.com.au via
				email at hello@superstrategyreview.com.au.
			</Text>
			<Text>
				superstrategyreview.com.au will take all reasonable steps to allow a
				User to access the User&apos;s personal information unless such access would
				have an unreasonable impact on the privacy of other individuals and
				other Users.
			</Text>
			<Text>
				superstrategyreview.com.au reserves the right to amend and update this
				Privacy Policy at any time.
			</Text>
			<Text>
				A User can contact superstrategyreview.com.au at any time on
				(hello@superstrategyreview.com.au) to opt out of direct marketing with
				the subject line as Unsubscribe.
			</Text>
			<Text>
				If entrants do not consent to their details being used for future
				marketing purposes, the entrant should contact the
				superstrategyreview.com.au by email or post on the contact details on
				their website. Any request to update, modify or delete the entrant&apos;s
				details should be directed to superstrategyreview.com.au via email at
				hello@superstrategyreview.com.au.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Third-party Sites
			</Heading>
			<Text>
				superstrategyreview.com.au may pass the User’s personal information to
				relevant third-party businesses to complete their request.
				superstrategyreview.com.au is not responsible for the privacy practices
				and policies for websites and applications not owned or managed by
				superstrategyreview.com.au. It is the Users responsibility to be
				familiar with and understand the privacy policies of other third-party
				sites.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Cookies and Usage Tracking
			</Heading>
			<Text>
				superstrategyreview.com.au and any Associate or relevant third party may
				use small text files called “cookies”:
			</Text>
			<UnorderedList spacing={4}>
				<ListItem>
					To enable more efficient browsing of the superstrategyreview.com.au
					Platform;
				</ListItem>
				<ListItem>
					To keep the User logged in to the website as they move from page to
					page;
				</ListItem>
			</UnorderedList>
			<Text>
				For statistical purposes, such as to determine which pages visitors view
				most often, and to provide a better browsing experience.
			</Text>
			<Text>
				Cookies are stored on the Users computer until they are deleted. User
				browser settings may be adjusted to refuse cookies from the website.
				However, please note that this may affect your ability to use the
				superstrategyreview.com.au Platform. Read more about cookie usage in our
				Terms and Conditions
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Complaints
			</Heading>
			<Text>
				If you believe your privacy has been breached by us, have any questions
				or concerns about our Privacy Policy please, contact us using the
				contact information below and provide details of the incident so that we
				can investigate it. We have a formal procedure for investigating and
				dealing with privacy breaches. Once the Privacy Officer receives a
				complaint, whether it is in writing or verbal means, the Privacy Officer
				will commence an investigation with the relevant business unit from
				which the alleged breach stemmed. The investigator will endeavor to
				determine the nature of the breach and how it occurred. We may contact
				you during the process to seek any further clarification if necessary.
			</Text>
			<Text>
				If a breach is found, the Privacy Officer will escalate the matter to
				management so that the process can be rectified to prevent any further
				breaches from taking place. We will also contact you to inform you of
				the outcome of the investigation. We will endeavor to resolve all
				investigations within a reasonable time.
			</Text>
			<Text>
				We will treat your requests or complaints confidentially. Our
				representative will contact you within a reasonable time after receipt
				of your complaint to discuss your concerns and outline options regarding
				how they may be resolved. We will aim to ensure that your complaint is
				resolved in a timely and appropriate manner.
			</Text>
			<Text>Please contact our Privacy Officer in writing at:</Text>
			<Text>Privacy Officer: AMBA Solutions Pty Ltd</Text>
			<Text>Email: hello@superstrategyreview.com.au</Text>
			<Text>Postal Address: Level 3, 360 Kent St, Sydney, NSW, 2000</Text>
			<Text>
				Use of this site means that you accept the practices set forth in this
				policy. Your continued use indicates your agreement to the changes.
			</Text>
		</LawPagesLayout>
	);
};

export default PrivacyPolicy;
