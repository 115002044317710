import { FC } from "react";

interface ArrowAxisXProps {
	rotate?: boolean;
	color?: string;
}

export const ArrowAxisX: FC<ArrowAxisXProps> = ({ rotate, color }) => {
	return (
		<svg
			rotate={rotate ? "180deg" : "0"}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" />
			<g id="Icons">
				<rect x="-220" y="-80" width="2415" height="1196" rx="20" />
				<g id="Arrow Right">
					<g id="Vector" opacity="0.2">
						<path
							d="M16.0003 29.3334C23.3641 29.3334 29.3337 23.3639 29.3337 16.0001C29.3337 8.63628 23.3641 2.66675 16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001C2.66699 23.3639 8.63653 29.3334 16.0003 29.3334Z"
							fill={color || "#001C3D"}
						/>
						<path
							d="M16.0003 29.3334C23.3641 29.3334 29.3337 23.3639 29.3337 16.0001C29.3337 8.63628 23.3641 2.66675 16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001C2.66699 23.3639 8.63653 29.3334 16.0003 29.3334Z"
							fill="black"
							fillOpacity="0.2"
						/>
					</g>
					<g id="Vector_2">
						<path
							d="M18.0408 11.2929C17.6502 10.9024 17.0171 10.9024 16.6266 11.2929C16.236 11.6834 16.236 12.3166 16.6266 12.7071L18.9194 15H10.667C10.1147 15 9.66699 15.4477 9.66699 16C9.66699 16.5523 10.1147 17 10.667 17H18.9194L16.6266 19.2929C16.236 19.6834 16.236 20.3166 16.6266 20.7071C17.0171 21.0976 17.6502 21.0976 18.0408 20.7071L22.0408 16.7071C22.4313 16.3166 22.4313 15.6834 22.0408 15.2929L18.0408 11.2929Z"
							fill={color || "#001C3D"}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
