import { useLocation } from "react-router-dom";

export const useUTMParams = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const utm_source = queryParams.get("utm_source") || "";
	const utm_medium = queryParams.get("utm_medium") || "";
	const utm_campaign = queryParams.get("utm_campaign") || "";
	const utm_term = queryParams.get("utm_term") || "";
	const utm_content = queryParams.get("utm_content") || "";

	return { utm_source, utm_medium, utm_campaign, utm_term, utm_content };
};
