import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Footer } from "./components/footer/Footer";
import { Steps } from "./components/steps/Steps";
import { useEffect, useState } from "react";
import { CustomRange } from "./components/range/Range";
import { PrimaryButton } from "./components/buttons/PrimaryButton";
import { CompanyTitle } from "./components/companyTitle/CompanyTitle";
import useEnterKeyPress from "./utils/hooks/useEnterKeyPress";
import { StepEnum } from "./utils/types/StepEnum";
import { getQuizTitle } from "./utils/hooks/getQuizTitle";
import { BorderedButton } from "./components/buttons/BorderedButton";
import { SECOND_STEP_OPTIONS } from "./utils/consts/secondStepOptions";
import Dropdown from "./components/dropdown/Dropdown";
import { FourthStep } from "./components/stepsContent/fourthStep/FourthStep";
import InfoIcon from "./assets/icons/InfoIcon";
import { FIFTH_STEP_OPTIONS } from "./utils/consts/fifthStepOptions";
import { SEVENTH_STEP_OPTIONS } from "./utils/consts/seventhStepOptions";
import axios from "axios";
import CalculatingResults from "./components/calculatingResults/CalculatingResults";
import { setSessionStorageValue } from "./utils/sessionStorageContoller/setSessionStorageValue";
import { useNavigate } from "react-router-dom";
import { useUTMParams } from "./utils/hooks/useUTMParams";
import AnimatedCursor from "./components/animatedCursor/AnimatedCursor";
import { getCompanyNameByDomain } from "./utils/helpers/getCompanyNameByDomain";
import CheckBlueIcon from "./assets/icons/CheckBlueIcon";

const FIRST_STEP_RANGE_VALUE_KEY = "FIRST_STEP_RANGE_VALUE_KEY";
const SIXTH_STEP_RANGE_VALUE_KEY = "SIXTH_STEP_RANGE_VALUE_KEY";
export const STEP_KEY = "STEP_KEY";

const App = () => {
  const companyName = getCompanyNameByDomain();
  const navigate = useNavigate();
  const utmParams = useUTMParams();
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  sessionStorage.setItem("UTM_PARAMS", JSON.stringify(utmParams));
  const CALCULATION_DATA = sessionStorage.getItem("CALCULATION_DATA");
  const [isFirstSliderMoved, setFirstSliderMoved] = useState(false);
  const [isSecondSliderMoved, setSecondSliderMoved] = useState(false);

  const [age, setAge] = useState(sessionStorage.getItem("age") || "");
  const [isAgeValid, setIsAgeValid] = useState(true);
  const [valuePerWeek, setValuePerWeek] = useState(
    Number(sessionStorage.getItem("valuePerWeek")) || 0
  );
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [currentStep, setCurrentStep] = useState(
    Number(sessionStorage.getItem(STEP_KEY)) || StepEnum.FIRST
  );

  useEffect(() => {
    setSessionStorageValue(currentStep);
    updateURL(currentStep);
  }, [currentStep]);

  function updateURL(currentStep: number) {
    const newPath = currentStep === 1 ? "/" : `/step-${currentStep}`;
    history.pushState({ step: currentStep }, "", newPath);
    setSessionStorageValue(currentStep);
  }

  window.onpopstate = function () {
    history.pushState(null, "", "/");
    setSessionStorageValue(1);
  };

  window.addEventListener("popstate", function () {
    window.history.forward();
  });

  document.addEventListener("DOMContentLoaded", function () {
    history.pushState(null, "", "/");
    setSessionStorageValue(1);
  });

  const quizTitle = getQuizTitle(currentStep);

  useEnterKeyPress(() => {
    switch (currentStep) {
      case StepEnum.FIRST:
        isFirstSliderMoved && setCurrentStep(StepEnum.SECOND);

        break;
      case StepEnum.SECOND:
        if (!!age && isAgeValid) {
          setCurrentStep(StepEnum.THIRD);
        }
        break;
      case StepEnum.FOURTH:
        isSecondSliderMoved && setCurrentStep(StepEnum.FIFTH);

        break;
      case StepEnum.FIFTH:
        return !!valuePerWeek && setCurrentStep(StepEnum.CALCULATING);
      default:
        return;
    }
  });

  useEffect(() => {
    axios
      .get("https://api.superstrategyreview.com.au/api/superannuation/funds")
      .then((response) => {
        setDropdownOptions(response.data.funds);
      });
  }, []);

  const formattedDropdownOptions = dropdownOptions.map((option) => {
    return { value: option, label: option };
  });

  return (
    <>
      {!CALCULATION_DATA && (
        <Box bg={"blue"} as="main">
          <Flex
            h={{ base: "56px", tablet: 20 }}
            borderBottom={"2px solid"}
            borderColor={"border"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CompanyTitle maxW="170px" />
          </Flex>

          <Box
            px={{ base: "16px", tablet: "40px", xl: "80px" }}
            bg={"blue"}
            as="section"
          >
            <Container
              maxW={{ xl: "1520px" }}
              pt={{ base: 4, xl: 8 }}
              px={0}
              pb={{ base: 10, xl: "60px" }}
            >
              <Flex flexDirection={"column"} alignItems={"center"}>
                <Heading
                  // mt={{ base: 6, tablet: 10 }}
                  mb={{ base: "14px", tablet: 6 }}
                  maxW={"1008px"}
                  w={"100%"}
                  textAlign={"center"}
                  size={{ base: "fit", tablet: "md", xl: "lg" }}
                >
                  Do You Qualify to Boost Your Super Returns in 2024?
                </Heading>
                <Text
                  mb={{ base: 8, tablet: 10 }}
                  maxW={"810px"}
                  textAlign={"center"}
                  fontSize={{ base: "18px", tablet: "20px", xl: "24px" }}
                  lineHeight={{
                    base: "25.2px",
                    tablet: "28px",
                    xl: "34px",
                  }}
                  color={"dark"}
                  letterSpacing={"-0.01em"}
                >
                  We help working Australians target alternative funds returning
                  up to 10% per year to increase their retirement by hundreds of
                  thousands.
                </Text>

                <Box
                  pt={{ base: "14px", tablet: 6 }}
                  pb={{ base: 4, tablet: 10 }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", xl: "452px" }}
                  border={"2px solid #E5EBF5"}
                >
                  {currentStep !== StepEnum.CALCULATING ? (
                    <>
                      <Box
                        w={"100%"}
                        px={{ base: 4, tablet: "8" }}
                        pb={{ base: "13px", tablet: 6 }}
                        borderBottom={"2px solid #E5EBF5"}
                      >
                        <Steps currentStep={currentStep} />
                      </Box>
                      <Box
                        p={{
                          base: "32px 16px 0",
                          tablet: "40px 40px 0",
                          xl: "60px 60px 0",
                        }}
                      >
                        <Heading
                          size={{ base: "sm", xl: "md" }}
                          textAlign={"center"}
                          mb={
                            currentStep !== StepEnum.FIFTH
                              ? {
                                  base: 4,
                                  tablet: "80px",
                                  xl: "100px",
                                }
                              : "38px"
                          }
                        >
                          <Flex
                            w={"100%"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"10px"}
                          >
                            {quizTitle}
                            {currentStep === StepEnum.SECOND && (
                              <Box role="group">
                                <Tooltip
                                  isOpen={isOpen}
                                  borderRadius={"12px"}
                                  bg={"dark"}
                                  placement={"top-start"}
                                  label="To provide retirement projections, we need to calculate the years you have left until retirement"
                                  fontSize="14px"
                                  lineHeight={5}
                                  color={"white"}
                                  p={3}
                                  minW={{ base: "311px", xl: "370px" }}
                                >
                                  <Box
                                    onMouseEnter={onOpen}
                                    onMouseLeave={onClose}
                                    onClick={onToggle}
                                    cursor={"pointer"}
                                  >
                                    <Box _groupHover={{ display: "none" }}>
                                      <InfoIcon />
                                    </Box>
                                    <Box
                                      display={"none"}
                                      _groupHover={{ display: "block" }}
                                    >
                                      <InfoIcon
                                        opacity={1}
                                        fill="#001C3D"
                                        color="white"
                                      />
                                    </Box>
                                  </Box>
                                </Tooltip>
                              </Box>
                            )}
                          </Flex>
                        </Heading>
                        {currentStep === StepEnum.FIRST && (
                          <Flex
                            flexDirection={{ base: "column", xl: "row" }}
                            gap={{ base: 4, tablet: 10, xl: 4 }}
                            justifyContent={"space-between"}
                          >
                            <Box w={"100%"}>
                              <CustomRange
                                setIsSliderMoved={() =>
                                  setFirstSliderMoved(true)
                                }
                                localStorageKey={FIRST_STEP_RANGE_VALUE_KEY}
                              />
                              {/* <AnimatedCursor
                                display={{ base: "none", tablet: "flex" }}
                              /> */}
                            </Box>
                            <Box w={{ base: "100%", xl: "fit-content" }}>
                              <PrimaryButton
                                onClick={() => {
                                  setCurrentStep(StepEnum.SECOND);
                                }}
                                size={{ base: "mobile", tablet: "md" }}
                                width="100%"
                                tooltipVisible
                                innerText={"Next"}
                                isDisabled={!isFirstSliderMoved}
                              />
                            </Box>
                          </Flex>
                        )}
                        {currentStep === StepEnum.SECOND && (
                          <FourthStep
                            age={age}
                            setAge={(val) => setAge(val)}
                            isAgeValid={isAgeValid}
                            setIsAgeValid={(val) => setIsAgeValid(val)}
                            setCurrentStep={() => {
                              setCurrentStep(StepEnum.THIRD);
                            }}
                          />
                        )}

                        {currentStep === StepEnum.THIRD && (
                          <Flex
                            m={{ base: "0 auto", xl: "0 auto 74px" }}
                            w={"100%"}
                            gap={{ base: 2, xl: 4 }}
                            flexDirection={{ base: "column", tablet: "row" }}
                            justifyContent={"center"}
                          >
                            {FIFTH_STEP_OPTIONS.map((option, index) => {
                              return (
                                <BorderedButton
                                  key={index}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "E_STATUS",
                                      option.title.replace(/\s+/g, "_")
                                    );
                                    setCurrentStep(StepEnum.FOURTH);
                                  }}
                                  size={{ base: "mobile", tablet: "md" }}
                                >
                                  {option.title}
                                </BorderedButton>
                              );
                            })}
                          </Flex>
                        )}
                        {currentStep === StepEnum.FOURTH && (
                          <Flex
                            flexDirection={{ base: "column", xl: "row" }}
                            gap={{ base: 4, tablet: 10, xl: 4 }}
                            justifyContent={"space-between"}
                          >
                            <Box w={"100%"}>
                              <CustomRange
                                setIsSliderMoved={() =>
                                  setSecondSliderMoved(true)
                                }
                                localStorageKey={SIXTH_STEP_RANGE_VALUE_KEY}
                              />
                            </Box>
                            <Box w={{ base: "100%", xl: "fit-content" }}>
                              <PrimaryButton
                                onClick={() => {
                                  setCurrentStep(StepEnum.FIFTH);
                                }}
                                size={{ base: "mobile", tablet: "md" }}
                                width="100%"
                                tooltipVisible
                                innerText={"Next"}
                                isDisabled={!isSecondSliderMoved}
                              />
                            </Box>
                          </Flex>
                        )}
                        {currentStep === StepEnum.FIFTH && (
                          <Grid
                            maxW={{
                              base: "836px",
                              // tablet: "410px",
                              // xl: "100%",
                            }}
                            m={"0 auto"}
                            templateColumns={{
                              md: "repeat(3, 1fr)",
                              xl: "repeat(6, 1fr)",
                            }}
                            gap={{ base: 2, tablet: 4 }}
                            pb={{ base: 0, xl: "30px" }}
                          >
                            {SEVENTH_STEP_OPTIONS.map((option, index) => {
                              if (index !== SEVENTH_STEP_OPTIONS.length - 1) {
                                return (
                                  <GridItem w={"100%"} key={index}>
                                    <BorderedButton
                                      className={
                                        +option.title === valuePerWeek
                                          ? "active"
                                          : ""
                                      }
                                      size={{ base: "mobile", tablet: "md" }}
                                      width="100%"
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "valuePerWeek",
                                          option.title
                                        );
                                        setValuePerWeek(Number(option.title));
                                      }}
                                    >
                                      ${option.title}
                                    </BorderedButton>
                                  </GridItem>
                                );
                              } else {
                                return (
                                  <GridItem
                                    pt={{ base: 4, tablet: "0" }}
                                    w={"100%"}
                                    colSpan={3}
                                    key={index}
                                  >
                                    <PrimaryButton
                                      size={{ base: "mobile", tablet: "md" }}
                                      isDisabled={!valuePerWeek}
                                      width="100%"
                                      innerText={option.title}
                                      onClick={() => {
                                        setCurrentStep(StepEnum.CALCULATING);
                                      }}
                                      tooltipVisible={!!valuePerWeek}
                                    />
                                  </GridItem>
                                );
                              }
                            })}
                          </Grid>
                        )}
                      </Box>
                    </>
                  ) : (
                    <CalculatingResults />
                  )}
                </Box>
              </Flex>
            </Container>
          </Box>
          <Text
            fontStyle={"italic"}
            fontSize={{ base: "16px", tablet: "24px" }}
            mb={"62px"}
            mx={"auto"}
            textAlign={"center"}
            maxWidth={{ base: "70%", tablet: "100%" }}
          >
            It takes under 60 seconds and is 100% obligation-free.
          </Text>
          <Footer />
        </Box>
      )}
    </>
  );
};

export default App;
