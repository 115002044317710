export const StepDoneIcon = () => {
	return (
		<svg
			className="step-icon"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.0414 1.67694C18.8766 0.636716 21.1234 0.636714 22.9586 1.67693L28.7 4.93116L34.3889 8.27624C36.2074 9.34551 37.3308 11.2912 37.3475 13.4007L37.4 20L37.3475 26.5993C37.3308 28.7088 36.2074 30.6545 34.3889 31.7238L28.7 35.0688L22.9586 38.3231C21.1234 39.3633 18.8766 39.3633 17.0414 38.3231L11.3 35.0688L5.61106 31.7238C3.79258 30.6545 2.66923 28.7088 2.65246 26.5993L2.6 20L2.65246 13.4007C2.66923 11.2912 3.79258 9.34551 5.61106 8.27624L11.3 4.93116L17.0414 1.67694Z"
				fill="#001C3D"
			/>
			<path
				d="M17.0414 1.67694C18.8766 0.636716 21.1234 0.636714 22.9586 1.67693L28.7 4.93116L34.3889 8.27624C36.2074 9.34551 37.3308 11.2912 37.3475 13.4007L37.4 20L37.3475 26.5993C37.3308 28.7088 36.2074 30.6545 34.3889 31.7238L28.7 35.0688L22.9586 38.3231C21.1234 39.3633 18.8766 39.3633 17.0414 38.3231L11.3 35.0688L5.61106 31.7238C3.79258 30.6545 2.66923 28.7088 2.65246 26.5993L2.6 20L2.65246 13.4007C2.66923 11.2912 3.79258 9.34551 5.61106 8.27624L11.3 4.93116L17.0414 1.67694Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M25.4717 17.2718C25.9733 16.7101 25.9245 15.848 25.3627 15.3465C24.801 14.8449 23.9389 14.8937 23.4374 15.4554L17.961 21.5889L16.3808 19.8191C15.8792 19.2573 15.0172 19.2085 14.4554 19.7101C13.8937 20.2117 13.8449 21.0737 14.3465 21.6355L16.9439 24.5446C17.2026 24.8343 17.5726 25 17.961 25C18.3495 25 18.7195 24.8343 18.9782 24.5446L25.4717 17.2718Z"
				fill="white"
			/>
		</svg>
	);
};
