import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./config/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import ScrollToTop from "./utils/scrollToTop/ScrollToTop";
import ResultPage from "./pages/resultPage/ResultPage";
import SuccessBookingAppointment from "./pages/successBookingAppointment/SuccessBookingAppointment";
import CongratulationPage from "./pages/congratulationPage/CongratulationPage";
import NotQualifyPage from "./pages/notQualifyPage/NotQualifyPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route element={<App />} path="/" />
            <Route element={<App />} path="/step-2" />
            <Route element={<App />} path="/step-3" />
            <Route element={<App />} path="/step-4" />
            <Route element={<App />} path="/step-5" />
            <Route element={<App />} path="/step-6" />

            <Route element={<PrivacyPolicy />} path="/privacy-policy" />
            <Route
              element={<TermsAndConditions />}
              path="/terms-and-conditions"
            />
            <Route element={<CongratulationPage />} path="/step-7" />
            <Route element={<CongratulationPage />} path="/step-8" />
            <Route element={<CongratulationPage />} path="/step-9" />
            <Route element={<CongratulationPage />} path="/step-10" />
            <Route element={<CongratulationPage />} path="/step-11" />
            <Route element={<ResultPage />} path="/result" />
            <Route
              element={<SuccessBookingAppointment />}
              path="/booked-appointment"
            />
            <Route element={<NotQualifyPage />} path="/dnq" />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
