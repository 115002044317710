import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { Range } from "react-range";
import { RangeIcon } from "../../assets/icons/RangeIcon";
import { divideNumberByGrades } from "../../utils/helpers/divideNumberByGrades";

const STEP = 10000;
const MAX_VALUE = 500000;

interface CustomRangeProps {
  localStorageKey: string;
  setIsSliderMoved: (value: boolean) => void;
}

export const CustomRange: FC<CustomRangeProps> = ({ localStorageKey, setIsSliderMoved }) => {
  const [values, setValues] = useState([
    Number(sessionStorage.getItem(localStorageKey)) || STEP,
  ]);

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, values[0].toString());
  }, [values]);

  return (
    <Flex
      flexDirection={{ base: "column", tablet: "row" }}
      justifyContent={"center"}
    >
      <Flex
        display={{ base: "flex", tablet: "none" }}
        m="0 auto 8px"
        w={"fit-content"}
        border={"2px solid"}
        px={"18px"}
        borderRadius={"12px"}
        py={3}
        borderColor={"border"}
        fontSize={"14px"}
        lineHeight={6}
        fontWeight={500}
        color={"dark"}
      >
        ${divideNumberByGrades(values[0])}
      </Flex>

      <Flex
        order={0}
        // maxW={"1037px"}
        w={"100%"}
        alignItems={"center"}
        border={"2px solid #E5EBF5"}
        borderRadius={"20px"}
        h={20}
        bg={"#f8faff"}
        px={{ base: "34px", tablet: "92px" }}
      >
        <Range
          step={STEP}
          min={STEP}
          max={MAX_VALUE}
          values={values}
          onChange={(newValues) => {
            setValues(newValues);
            setIsSliderMoved(true);
          }}
          renderMark={({ props, index }) => {
            return (
              <Box
                {...props}
                pointerEvents={{ base: "none", tablet: "all" }}
                w={"6px"}
                h={"6px"}
                display={{
                  base: `${index % 2 ? "block" : "none"}`,
                  lg: "block",
                }}
                style={{
                  ...props.style,
                  left: `${index === 1 ? 0 : props.style.left}`,
                  backgroundColor:
                    (index + 1) * STEP === values[0] ? "#001C3D" : "#E5EBF5",
                }}
                borderRadius={"100%"}
              />
            );
          }}
          renderTrack={({ props, children }) => (
            <Box
              height={{ base: 0, tablet: "64px" }}
              position={"relative"}
              {...props}
              style={{
                ...props.style,

                width: "100%",
              }}
            >
              {children}
              <Box
                pointerEvents={{ base: "none", tablet: "all" }}
                position={"absolute"}
                left={0}
                top={"50%"}
                w={"100%"}
                zIndex={-1}
                transform={"translateY(-50%)"}
                h={"2px"}
                bg={"border"}
              />
            </Box>
          )}
          renderThumb={({ props }) => (
            <Flex
              fontWeight={500}
              w={{ base: "56px", tablet: "172px" }}
              outline={"none"}
              {...props}
              style={{
                ...props.style,
                height: "64px",
                backgroundColor: "#001C3D",
                borderRadius: "14px",
                color: "#ffffff",
              }}
            >
              <Flex
                w={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  height: "64px",
                }}
                gap={"10.5px"}
              >
                <RangeIcon />
                <Box display={{ base: "none", tablet: "block" }}>
                  ${divideNumberByGrades(values[0])}
                </Box>
                <RangeIcon rotate />
              </Flex>
            </Flex>
          )}
        />
      </Flex>
    </Flex>
  );
};
