import React from "react";

const CheckIcon = () => {
	return (
		<svg
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.2"
				d="M29.8337 16.0001C29.8337 23.3639 23.8641 29.3334 16.5003 29.3334C9.13653 29.3334 3.16699 23.3639 3.16699 16.0001C3.16699 8.63628 9.13653 2.66675 16.5003 2.66675C23.8641 2.66675 29.8337 8.63628 29.8337 16.0001Z"
				fill="#00A023"
			/>
			<path
				d="M21.8734 11.9596C22.264 12.3502 22.264 12.9833 21.8734 13.3739L15.2068 20.0405C14.8163 20.431 14.1831 20.431 13.7926 20.0405L11.1259 17.3739C10.7354 16.9833 10.7354 16.3502 11.1259 15.9596C11.5164 15.5691 12.1496 15.5691 12.5401 15.9596L14.4997 17.9192L17.4795 14.9394L20.4592 11.9596C20.8498 11.5691 21.4829 11.5691 21.8734 11.9596Z"
				fill="#00A023"
			/>
		</svg>
	);
};

export default CheckIcon;
