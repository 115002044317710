/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Flex,
  Heading,
  Grid,
  GridItem,
  Box,
  Text,
  Container,
  Button,
} from "@chakra-ui/react";
import Chart from "../../components/chart/Chart";
import RestartIcon from "../../assets/icons/RestartIcon";
import { CompanyTitle } from "../../components/companyTitle/CompanyTitle";
import { divideNumberByGrades } from "../../utils/helpers/divideNumberByGrades";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Footer } from "../../components/footer/Footer";

const NotQualifyPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const sessionStorageCalculationData =
    sessionStorage.getItem("CALCULATION_DATA");
  const calculationData = sessionStorageCalculationData
    ? JSON.parse(sessionStorageCalculationData)
    : null;
  return (
    <Box bg={"blue"} as="main">
      <Flex
        h={{ base: "56px", tablet: 20 }}
        borderBottom={"2px solid"}
        borderColor={"border"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CompanyTitle maxW="170px" />
      </Flex>
      <Box
        pb={10}
        px={{ base: "16px", tablet: "40px", xl: "80px" }}
        as="section"
      >
        <Container maxW={"1520px"} p={0}>
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Heading
              textAlign={"center"}
              mt={{ base: 4, tablet: 8 }}
              mb={{ base: "14px", tablet: 6 }}
              size={{ base: "mobile", tablet: "md", xl: "lg" }}
            >
              As promised, here are your results
            </Heading>
            <Button
              mt={6}
              mb={"46px"}
              w={"226px"}
              rightIcon={<RestartIcon />}
              variant={"primary"}
              size={{ base: "mobile", tablet: "md" }}
              onClick={() => {
                sessionStorage.clear();
                navigate("/");
              }}
            >
              Restart Quiz
            </Button>
            <Grid
              gap={{ base: 2, xl: 4 }}
              w={"100%"}
              templateColumns={{
                base: "repeat(1, 1fr)",
                xl: "repeat(3, 1fr)",
              }}
            >
              <GridItem
                h={"88px"}
                borderRadius={"20px"}
                bg={"white"}
                p={6}
                w="100%"
                border={"2px solid #E5EBF5"}
              >
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={3}>
                    <Box
                      bg={"accent"}
                      w={1}
                      borderRadius={"4px"}
                      height={"100%"}
                    />
                    <Text
                      fontSize={"14px"}
                      lineHeight={5}
                      letterSpacing={"-0.01em"}
                      color={"grey"}
                      maxW={"139px"}
                    >
                      Approximate retirement balance
                    </Text>
                  </Flex>
                  <Text color={"dark"} fontSize={"24px"} lineHeight={"34px"}>
                    $
                    {divideNumberByGrades(
                      Math.trunc(calculationData.approximate_retirement_balance)
                    )}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem
                h={"88px"}
                borderRadius={"20px"}
                bg={"white"}
                p={6}
                w="100%"
                border={"2px solid #E5EBF5"}
              >
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={3}>
                    <Box
                      bg={"#EDA52F"}
                      w={1}
                      borderRadius={"4px"}
                      height={"100%"}
                    />
                    <Text
                      fontSize={"14px"}
                      lineHeight={5}
                      letterSpacing={"-0.01em"}
                      color={"grey"}
                      maxW={"139px"}
                    >
                      Your ideal <br />
                      retirement balance
                    </Text>
                  </Flex>
                  <Text color={"dark"} fontSize={"24px"} lineHeight={"34px"}>
                    $
                    {divideNumberByGrades(
                      Math.trunc(calculationData.your_ideal_retirement_balance)
                    )}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem
                h={"88px"}
                borderRadius={"20px"}
                bg={"white"}
                p={6}
                w="100%"
                border={"2px solid #E5EBF5"}
              >
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={3}>
                    <Box
                      bg={"negative"}
                      w={1}
                      borderRadius={"4px"}
                      height={"100%"}
                    />
                    <Text
                      fontSize={"14px"}
                      lineHeight={5}
                      letterSpacing={"-0.01em"}
                      color={"grey"}
                      maxW={"139px"}
                    >
                      Estimated retirement shortfall
                    </Text>
                  </Flex>
                  <Text color={"dark"} fontSize={"24px"} lineHeight={"34px"}>
                    $
                    {divideNumberByGrades(
                      Math.trunc(calculationData.estimated_retirement_shortfall)
                    )}
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
            <Box width={"100%"}>
              <Chart
                legend={[
                  {
                    title: "Approximate Retirement Balance",
                    color: "#227ED3",
                  },
                ]}
                datasets={[
                  {
                    label: "Approximate Retirement Balance",
                    data: Object.values(
                      calculationData.by_years.approximate_retirement_balance
                    ),
                    borderColor: "#247fd3",
                    backgroundColor: (context: {
                      chart: {
                        chartArea: any;
                        ctx: any;
                        data?: unknown;
                      };
                    }) => {
                      const bgColor = [
                        "rgba(34, 126, 211, 0.3)",
                        "rgba(255, 255, 255, 0)",
                      ];
                      if (!context.chart.chartArea) {
                        return;
                      }
                      const {
                        ctx,
                        chartArea: { top, bottom },
                      } = context.chart;
                      const gradientBg = ctx.createLinearGradient(
                        0,
                        top,
                        0,
                        bottom
                      );
                      gradientBg.addColorStop(0, bgColor[0]);
                      gradientBg.addColorStop(1, bgColor[1]);
                      return gradientBg;
                    },

                    fill: true,
                    pointBackgroundColor: "#fff",
                    pointRadius: Object.values(
                      calculationData.by_years.approximate_retirement_balance
                    ).map((_, index) => {
                      if (width <= 834) {
                        if (
                          index ===
                          Object.values(
                            calculationData.by_years
                              .approximate_retirement_balance
                          ).length -
                            1
                        ) {
                          return 4;
                        } else {
                          return 0;
                        }
                      } else {
                        return 4;
                      }
                    }),
                  },
                ]}
                labels={Object.keys(
                  calculationData.by_years
                    .estimated_alternative_fund_retirement_balance
                ).map((val) => val.replace("y_", ""))}
              />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default NotQualifyPage;
