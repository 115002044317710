import { useEffect } from "react";

const useEnterKeyPress = (callback: () => void) => {
	useEffect(() => {
		const keyDownHandler = (event: {
			key: string;
			preventDefault: () => void;
		}) => {
			if (event.key === "Enter") {
				event.preventDefault();
				callback();
			}
		};

		document.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, [callback]);
};

export default useEnterKeyPress;
