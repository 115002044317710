import LawPagesLayout from "../LawPagesLayout";
import { Heading, Text } from "@chakra-ui/react";

const TermsAndConditions = () => {
	return (
		<LawPagesLayout
			title="Terms & Conditions
        "
		>
			<Text>
				Superstrategyreview.com.au is owned and operated by AMBA Solutions Pty
				Ltd (ABN 74 623 403 190) and (we, us, AMBA) has the right to operate the
				superstrategyreview.com.au website (the Website) and these Terms &
				Conditions apply to your use of the Website. Your use of the Website
				including accessing or browsing the Website or uploading any material or
				personal data to the Website is deemed to be your acceptance of these
				Terms and Conditions. Please read these carefully and if you do not
				accept the Terms and Conditions set out below please exit the Website
				and refrain from using the Website.
			</Text>
			<Text>
				A User can contact superstrategyreview.com.au at any time on
				(hello@superstrategyreview.com.au) to opt out of direct marketing with
				the subject line as Unsubscribe, or by clicking the
				&apos;unsubscribe&apos; link found at the bottom of our digital
				communication, or in writing to: Super Strategy Review, Level 3, 360
				Kent St, Sydney, NSW, 2000.
			</Text>
			<Text>
				If you breach any provision of these Terms and Conditions your right to
				access the Website will be immediately revoked. The Website is for
				Australia residents only aged 18 years or over.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Intellectual Property
			</Heading>
			<Text>
				superstrategyreview.com.au uses the words &apos;Super Strategy
				Review&apos; as a trade mark in Australia (&apos;Trade Mark&apos;). You
				are not permitted to use the Trade Mark or any other trademarks which
				belong or are licensed to superstrategyreview.com.au or any of
				superstrategyreview.com.au Intellectual Property which includes but is
				not limited to copyright material, confidential information, designs and
				other content located on the Website without superstrategyreview.com.au
				prior written authorisation.
			</Text>
			<Text>
				The information, images and text on the website may be subject to
				copyright and are the property of or otherwise property that is licensed
				to superstrategyreview.com.au. Trademarks used on the Website to
				describe other companies and their products or services are trademarks
				which belong to or are licensed to those companies (Other Trade Marks).
			</Text>
			<Text>
				The Other Trade Marks are displayed on the Website to provide
				information to you about other products or services via the Website.
			</Text>
			<Text>
				You agree not to download, copy, reproduce or in any way use the Other
				Trade Marks without authorisation from the owner/s of the Other Trade
				Marks.
			</Text>
			<Text>
				You acknowledge that you are expressly prohibited from using the phrase
				&apos;Super Strategy Review&apos; or any part of the Website domain name
				without the written consent of superstrategyreview.com.au. You agree
				that you are prohibited from reverse engineering the Website or
				otherwise attempting to copy the Website functionality or obtain from
				the Website information or data including but not limited to User&apos;s
				personal information.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Information and Liability
			</Heading>
			<Text>
				You acknowledge that superstrategyreview.com.au is not liable or
				responsible for any errors or omissions in the content of the Website.
				The information contained on the Website is provided for general
				information purposes only. superstrategyreview.com.au make no warranties
				as to the accuracy, completeness or risks in relation to the
				recommendations, information or material contained on the Website.
			</Text>
			<Text>
				You acknowledge that there are risks associated with using the internet
				and World Wide Web and to the fullest extent permitted by law,
				superstrategyreview.com.au or its affiliates or subsidiaries are not
				liable for any direct or indirect damages or losses arising by way of
				your use or access to the Website or outcome of using the website.
			</Text>
			<Text>
				You acknowledge that superstrategyreview.com.au makes no warranty that
				the Website will meet your User requirements or that the information
				contained on the Website or your use of the Website will be
				uninterrupted, timely, secure, error free, accurate, virus-free or
				compliant with legislation or regulations.
			</Text>
			<Text>
				You acknowledge and agree that superstrategyreview.com.au have no
				liability of any kind either express or implied with respect to any
				claims arising in any way from your use of the Website, by virtue of the
				information provided or contained on the Website.
			</Text>
			<Text>
				The Website contains general statements regarding superannuation and
				then pass your details onto a third party. You acknowledge that it is
				your responsibility to consider the third party service provider’s
				disclosure documents, such as the financial services guide and/or
				product disclosure statement (pds), before you engage the provider. If
				you decide to proceed with a service offering after a referral from
				superstrategyreview.com.au, you will be dealing directly with the
				service provider and not with AMBA. AMBA does not play a role in the
				provision of advice. AMBA is not responsible for services provided by
				third party service providers and, to the maximum extent permitted by
				law, is not liable for any loss or damage suffered as a result of the
				use or reliance on services provided by those third party service
				providers. AMBA does not make any representation as to the quality or
				suitability of the financial product advice provided by any financial
				adviser to whom it refers users of this website.
			</Text>
			<Text>
				You acknowledge that superstrategyreview.com.au may from time to time
				improve or make changes to the Website to upgrade security on the
				Website, update information on the Website or for any other reason in
				superstrategyreview.com.au sole discretion.
			</Text>
			<Text>
				You acknowledge that you are responsible for ensuring the protection of
				your electronic equipment when accessing or browsing the Website and
				unreservedly hold superstrategyreview.com.au harmless in respect to
				damage sustained to your electronic equipment or losses incurred by you
				as a result of the Website.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Invitation to Contact
			</Heading>
			<Text>
				1. Submitting your information through any of our available fields
				constitutes opt-in consent to contact and authorises us to pass your
				details onto a third party (who we choose to appoint and can change at
				any time) to contact you via phone, SMS, MMS, emails or any other
				electronic or direct methods. This contact will be of a commercial
				nature and to provide you with a performance review or comparison of
				your superannuation fund, provide you with services, news, information,
				marketing material or advice (according to their relevant licensing
				abilities or restrictions) for an infinite time period or until you
				unsubscribe.
			</Text>
			<Text>
				2. If your phone number is on the Do Not Call Register, you confirm and
				consent that the communication from superstrategyreview.com.au or any of
				its appointed partners is legal and solicited. Opt-in consent via
				superstrategyreview.com.au supersedes DNCR registration for the purposes
				of communication regarding your request for information or services via
				superstrategyreview.com.au. This may be done via a phone call, email,
				sms, mms or other direct or indirect communication.
			</Text>
			<Text>
				3. You authorise and consent to us and third-party of our choice to
				contact you for marketing promotions, which include telephone, SMS, MMS,
				emails or direct mails or any other format of marketing.
			</Text>
			<Text>
				4. This consent is valid perpetually and unrestricted or until you
				choose to opt out. You can opt out of these communications by contacting
				us as detailed in our Privacy policy.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Privacy
			</Heading>
			<Text>
				superstrategyreview.com.au is committed to preserving the privacy of its
				Users and will ensure that all personal information collected will be
				kept secure and in accordance with any applicable privacy laws. By using
				the Website, you agree to provide certain personal information necessary
				to utilise the Website to its full capability.
			</Text>
			<Text>
				Whilst superstrategyreview.com.au agrees to ensure that all personal
				information is kept secure, you agree that superstrategyreview.com.au
				may provide your Personal Information to other companies for the purpose
				of providing you with a quote or information about a product of service.
				You acknowledge that superstrategyreview.com.au will receive
				remuneration for providing your Personal Information to third-parties.
			</Text>
			<Text>
				When you supply your personal information to the
				superstrategyreview.com.au website superstrategyreview.com.au receives
				payment and/or commissions from the sale of your personal information.
				You expressly permit superstrategyreview.com.au to sell your personal
				information in order for third parties to supply you with relevant
				information and commercial marketing communication.
			</Text>
			<Text>
				superstrategyreview.com.au reserves the right to cooperate fully with
				any law enforcement authorities relating to any lawful request for the
				disclosure of personal information of its Users. You acknowledge that
				you have read and agree to the terms of superstrategyreview.com.au
				Privacy Policy.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Use of Website
			</Heading>
			<Text>
				You acknowledge that your use of the Website is undertaken at your own
				risk and you unreservedly indemnify superstrategyreview.com.au from any
				responsibility in respect of information or content listed on the
				Website or any linked websites not controlled by
				superstrategyreview.com.au.
			</Text>
			<Text>
				You agree not to engage in any activity that is disruptive or is
				detrimental to the operation or function of the Website. You are
				prohibited from positing or transmitting unlawful, threatening,
				defamatory, obscene, or profane material on the Website.
				superstrategyreview.com.au may prevent you from accessing the Website if
				in superstrategyreview.com.au sole opinion you have violated or acted
				inconsistently with these Terms and Conditions.
			</Text>
			<Text>
				superstrategyreview.com.au will attempt to connect you with a relevant
				third party. However, sometimes this is subject to
				superstrategyreview.com.au ability to find or secure a commercial
				arrangement with a third party provider. In every case, you agree that
				superstrategyreview.com.au have no responsibility to ensure that the
				product or service being offered to you by a third party, is suitable
				for your needs, circumstances and budget. You indemnify
				superstrategyreview.com.au from the ramifications and outcomes of your
				engagement and interactions with any third party we may pass your
				details to.
			</Text>
			<Text>
				Nothing on the website constitutes or should be mistaken as an offer to
				sell or supply any goods or services to you. Nothing on the website
				constitutes or should be mistaken as financial advice.
				superstrategyreview.com.au does not provide financial product advice or
				dealing services, nor does superstrategyreview.com.au promote financial
				products or product issuers.
			</Text>
			<Text>
				Further, superstrategyreview.com.au make no warranty the information
				provided to you is the best, the cheapest or that the goods or services
				are fit for purpose or meet consumer warranties in Australia.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Links and Advertisements
			</Heading>
			<Text>
				You acknowledge that any links or advertisements containing links to
				external websites that are provided or located on the Website are for
				convenience only. superstrategyreview.com.au does not endorse or make
				any warranty with respect to any external websites or to the accuracy or
				reliability of the links or advertisements contained on the Website.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Security
			</Heading>
			<Text>
				superstrategyreview.com.au, from time to time, may use technology to
				improve security on the Website and you agree to cooperate with
				superstrategyreview.com.au and you are responsible to check and read the
				amended Terms and Conditions.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Cookies and Viruses
			</Heading>
			<Text>
				superstrategyreview.com.au uses its best endeavours to ensure the use of
				the best technology to protect its Users, but accepts no responsibility
				in relation to any damage to any electronic equipment used by you,
				including mobile devices, as a result of your use of the Website. You
				acknowledge that you are responsible for ensuring the protection of your
				electronic equipment and unreservedly indemnify
				superstrategyreview.com.au from any responsibility in respect of damage
				to any electronic equipment as a result of your use of the Website.
			</Text>
			<Text>
				We use a variety of different types of Cookies on our Sites. Different
				Cookies have different specific purposes but in general they are all
				used so that we can improve your experience in using our Sites and
				interacting with us. Some of the purposes of the different Cookies we
				use are described below.
			</Text>
			<Text>
				1. Some Cookies are essential to the Site in order to facilitate our
				log-in process and enable you to move around it and to use its features.
				Without these Cookies, we may not be able to provide certain services or
				features, and the Site will not perform as smoothly for you as we would
				like.
			</Text>
			<Text>
				2. We may use Cookies to allow us to remember the choices you make while
				browsing the Site, and to provide enhanced and more personalised content
				and features, such as customising a certain webpage, providing relevant
				advertising or editorial content, remembering if we have asked you to
				participate in a promotion and for other services you request, like
				watching a video, completing a form or commenting on a blog.
			</Text>
			<Text>
				3. We may use Cookies to receive and record information about your
				computer, device, and browser, potentially including your IP address,
				browser type, and other software or hardware information. If you access
				the Site from a mobile or other device, we may collect a unique device
				identifier assigned to that device (“UDID”), geolocation data, or other
				transactional information for that device.
			</Text>
			<Text>
				4. We and third parties may use analytics Cookies, which are sometimes
				called performance cookies, to collect information about your use of the
				Site and enable us to improve the way it works. Analytics Cookies
				collect information about how you use the Site, for instance, which
				pages you go to most. The information allows us to see the overall
				patterns of usage on the Site, help us record any difficulties you have
				with the Site and show us whether our advertising is effective or not.
			</Text>
			<Text>
				5. We and third parties may use advertising Cookies to deliver ads that
				we believe are more relevant to you and your interests. For example, we
				may use targeting or advertising Cookies to limit the number of times
				you see the same ad on our Site, to help measure the effectiveness of
				our advertising campaigns as well as to customise the advertising and
				content you receive on our Site.
			</Text>
			<Text>
				6. Social plug-in tracking Cookies can be used to track both members and
				non-members of social networks for additional purposes such as
				behavioural advertising, analytics, and market research.
			</Text>
			<Text>
				We aggregate information received from the various Cookies placed on
				your browser by our connected network of sites. We may also link our
				anonymous Cookie ID&apos;s with the anonymous Cookie ID&apos;s used by
				third parties, including some of our service providers. This improves
				our ability to provide you with more relevant editorial and advertising
				content based on your activity across our connected network. We may also
				associate your browser and/or device with other browsers or devices you
				use.
			</Text>
			<Text>
				All information derived from these Cookies may be combined with other
				information acquired from third parties, and we may share this
				information with other organisations, such as advertisers, provided it
				is anonymised.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				General
			</Heading>
			<Text>
				If any part of these Terms & Conditions is held to be illegal or
				unenforceable, you authorise superstrategyreview.com.au to amend or
				sever that part of the Terms & Conditions to make that part legal and
				enforceable or to ensure that the remaining parts of the Terms &
				Conditions remain in full force and effect.
			</Text>
			<Text>
				You acknowledge that superstrategyreview.com.au may vary these Terms &
				Conditions and you agree to be bound by that variation from that time
				when that variation is advised. You understand that it is your
				responsibility to regularly check these Terms and Conditions so that you
				are aware of any variations which apply. Your continued use of the
				Website constitutes your agreement to these Terms and Conditions and any
				variations to these Terms and Conditions.
			</Text>
			<Heading mt={4} as={"h3"} size={"sm"}>
				Applicable Law
			</Heading>
			<Text>
				You agree that the content of this Website is intended for Australian
				residents only and may not be available in your Country. The laws of the
				State of Queensland will apply with respect to these Terms and
				Conditions and your use of the Website. You submit to the exclusive
				jurisdiction of the applicable Courts located in the State of
				Queensland, Australia with respect to the Website and these Terms and
				Conditions.
			</Text>
		</LawPagesLayout>
	);
};

export default TermsAndConditions;
