import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { CompanyTitle } from "../../components/companyTitle/CompanyTitle";
import { Footer } from "../../components/footer/Footer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import Input from "../../components/input/Input";
import { BorderedButton } from "../../components/buttons/BorderedButton";
import { ArrowAxisX } from "../../assets/icons/ArrowAxisX";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import { setSessionStorageValue } from "../../utils/sessionStorageContoller/setSessionStorageValue";
import OtpInput from "react-otp-input";
import { useTimer } from "../../utils/hooks/useTimer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useEnterKeyPress from "../../utils/hooks/useEnterKeyPress";
import CheckWhite from "../../assets/icons/CheckWhite";

const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("Please enter your first name"),
  lastName: yup.string().trim().required("Please enter your last name"),
  email: yup
    .string()
    .trim()
    .matches(new RegExp(EMAIL_REGX), "Please enter the valid email")
    .required("Please enter your email"),
  phone: yup
    .string()
    .test(
      "is-minimum-10-numbers",
      "At least 10 numbers are required",
      (value) => {
        const numberOfDigits = (value?.match(/\d/g) || []).length;
        return numberOfDigits >= 10;
      }
    )
    .required("Please enter your phone number"),
  state: yup.string().required("Please select your state"),
});

const LIMIT = 30;

const STATE_OPTIONS = [
  { title: "NSW" },
  { title: "VIC" },
  { title: "QLD" },
  { title: "SA" },
  { title: "WA" },
  { title: "TAS" },
  { title: "ACT" },
  { title: "NT" },
];

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
}

const CongratulationPage = () => {
  const savedStep = window.location.href.match(/\/step-(\d+)/)?.[1] || "7";
  const [step, setStep] = useState(+savedStep);

  const [state, setCurrentState] = useState<null | string>(null);
  const [phoneRuleAgree, setPhoneRuleAgree] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [isPhoneSending, setPhoneSendings] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const navigate = useNavigate();
  const seconds = useTimer(LIMIT, disabled, () => {
    setDisabled(false)
    setButtonDisabled(false)
  });

  const sendZappier = () => {
    const sessionStorageCalculationData =
      sessionStorage.getItem("CALCULATION_DATA");
    const calculationData = sessionStorageCalculationData
      ? JSON.parse(sessionStorageCalculationData)
      : null;
    const sessionStorageUtmParams = sessionStorage.getItem("UTM_PARAMS") || "";
    const utmParams = sessionStorageUtmParams
      ? JSON.parse(sessionStorageUtmParams)
      : "";
    const firstName = sessionStorage.getItem("firstName");
    const lastName = sessionStorage.getItem("lastName");
    const email = sessionStorage.getItem("email");
    const phone = (sessionStorage.getItem("phone") || "")
      .replace(/\s/g, "")
      .substring(1);
    const state = sessionStorage.getItem("selectedState");

    axios.post("https://api.superstrategyreview.com.au/api/service", {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: `614${phone}`,
      state: state,
      age: Number(sessionStorage.getItem("age")),
      approximate_super_balance: Number(
        sessionStorage.getItem("FIRST_STEP_RANGE_VALUE_KEY")
      ),
      annual_income_before_tax: Number(
        sessionStorage.getItem("SIXTH_STEP_RANGE_VALUE_KEY")
      ),
      ideal_weekly_income_in_retirement: Number(
        sessionStorage.getItem("valuePerWeek")
      ),
      approximate_retirement_balance:
        calculationData.approximate_retirement_balance,
      retirement_shortfall: calculationData.estimated_retirement_shortfall,
      super_fund_name: sessionStorage.getItem("FUND"),
      super_fund_type: sessionStorage.getItem("SUPER_FUND"),
      creation_date: new Date().toISOString(),
      ...utmParams,
    });
  };

  window.onpopstate = function () {
    history.pushState(null, "", `/step-${step}`);
  };

  useEffect(() => {
    setOtpError(false);
    if (otp.length === 4) {
      sendZappier();
      axios
        .post("https://api.superstrategyreview.com.au/api/sms/verify", {
          code: otp,
        })
        .then(function (response) {
          navigate("/result");
        })
        .catch(function (error) {
          console.error(error);
          setOtpError(true);
        });
    }
  }, [otp]);

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    trigger,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      state: "",
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEnterKeyPress(() => {
    switch (step) {
      case 7:
        goToNextStates();
        break;
      case 8:
        handleSaveState();
        break;
      case 9:
        trigger("email");
        dirtyFields.email && !errors.email?.message && handleSaveEmail();
        break;
      case 10:
        !isPhoneSending &&
          phoneRuleAgree &&
          !errors.phone?.message &&
          handleSendPhone();
        break;
      default:
        return;
    }
  });

  const goToNextStates = () => {
    const firstNameValue = getValues("firstName");
    const lastNameValue = getValues("lastName");
    sessionStorage.setItem("firstName", firstNameValue);
    sessionStorage.setItem("lastName", lastNameValue);

    if (firstNameValue && lastNameValue) {
      setStep(8);
      updateURL(8);
    } else {
      trigger("firstName");
      trigger("lastName");
    }
  };

  function updateURL(currentStep: number) {
    const newPath = currentStep === 1 ? "/" : `/step-${currentStep}`;
    history.pushState({ step: currentStep }, "", newPath);
    setSessionStorageValue(currentStep);
  }

  const handleSaveState = () => {
    if (state) {
      setStep(9);
      updateURL(9);
      sessionStorage.setItem("selectedState", state);
    }
  };

  const handleSaveEmail = () => {
    const emailValue = getValues("email");
    if (emailValue) {
      setStep(10);
      updateURL(10);
      sessionStorage.setItem("email", emailValue);
    } else {
      trigger("email");
    }
  };

  const handleSendPhone = () => {
    const phoneValue = getValues("phone").replace(/\s/g, "").substring(1);

    if (phoneValue) {
      setPhoneSendings(true);
      axios
        .post("https://api.superstrategyreview.com.au/api/sms/send", {
          phone: `+61${phoneValue}`,
        })
        .then(function (response) {
          if (response.status === 200) {
            setDisabled(true);
            setButtonDisabled(true)
            setStep(11);
            updateURL(11);
            sessionStorage.setItem("phone", phoneValue);
            setPhoneSendings(false);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      trigger("phone");
    }
  };

  return (
    <>
      <Box bg={"blue"} pb={10} as="main">
        <Flex
          h={{ base: "56px", tablet: 20 }}
          borderBottom={"2px solid"}
          borderColor={"border"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CompanyTitle maxW="170px" />
        </Flex>
        <Box px={{ base: "16px", tablet: "40px", xl: "80px" }} as="section">
          <Container maxW={"1520px"} p={0}>
            <Flex flexDirection={"column"} alignItems={"center"}>
              <Heading
                textAlign={"center"}
                mt={{ base: 6, tablet: 10 }}
                mb={{ base: 4, tablet: 6 }}
                size={{ base: "mobile", tablet: "md", xl: "lg" }}
              >
                Congratulations!
              </Heading>
              <Heading
                mb={{ base: 10, tablet: 6, xl: "76px!important" }}
                maxW={{ base: "100%", tablet: "594px", xl: "686px!important" }}
                fontWeight={{ base: 400, tablet: 500 }}
                w={"100%"}
                textAlign={"center"}
                size={{ base: "xsm", tablet: "sm", xl: "md" }}
              >
                You
                <Text color={"accent"} as="span">
                  {" "}
                  qualify{" "}
                </Text>
                and are seconds away from seeing your potential performance
                results!
              </Heading>

              {step === 7 && (
                <Flex
                  pt={{ base: "32px", tablet: 10 }}
                  pb={{ base: 4, tablet: 10, xl: 20 }}
                  px={{ base: 4, tablet: 10 }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", xl: "452px" }}
                  border={"2px solid #E5EBF5"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}
                >
                  <Heading
                    textAlign={"center"}
                    size={{ base: "sm", tablet: "sm", xl: "md" }}
                    mb={{ base: 2, tablet: 4 }}
                  >
                    What’s your name?
                  </Heading>
                  <Text
                    textAlign={"center"}
                    mb={{ base: 10, tablet: "48px" }}
                    maxW={{ base: "100%", tablet: "629px" }}
                    w={"100%"}
                    fontSize={{ base: "14px", tablet: "20px" }}
                    lineHeight={{ base: 5, tablet: 7 }}
                    color={"grey"}
                  >
                    Our team will need to speak with you if you wish to discuss
                    how you may be able to target funds returning up to 10% per
                    year, and increase your retirement by hundreds of thousands.
                  </Text>

                  <Flex
                    w={{ base: "100%", tablet: "fit-content" }}
                    flexDirection={{ base: "column", tablet: "row" }}
                    gap={{ base: 2, tablet: 4 }}
                  >
                    <Box w={{ base: "100%", xl: "306px" }}>
                      <Input
                        label="First name"
                        control={control}
                        name={"firstName"}
                        maxLength={100}
                        trigger={() => trigger("firstName")}
                        error={errors.firstName?.message}
                        mbError={{ base: "0", tablet: -7 }}
                      />
                    </Box>
                    <Box w={{ base: "100%", xl: "306px" }}>
                      <Input
                        label="Last name"
                        control={control}
                        name={"lastName"}
                        maxLength={100}
                        trigger={() => trigger("lastName")}
                        error={errors.lastName?.message}
                        mbError={{ base: "0", tablet: -7 }}
                      />
                    </Box>

                    <Box
                      mt={{ base: 6, tablet: 0 }}
                      w={{ base: "100%", tablet: "fit-content" }}
                    >
                      <PrimaryButton
                        width="100%"
                        isDisabled={
                          !!errors.firstName?.message ||
                          !!errors.lastName?.message
                        }
                        innerText="Next"
                        tooltipVisible={
                          !errors.firstName?.message &&
                          !errors.lastName?.message
                        }
                        onClick={() => {
                          goToNextStates();
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                      />
                    </Box>
                  </Flex>
                </Flex>
              )}
              {step === 8 && (
                <Box
                  pt={{ base: 8, tablet: 10 }}
                  pb={{ base: 4, tablet: 10, xl: 20 }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", laptop: "452px" }}
                  border={"2px solid #E5EBF5"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  display={"flex"}
                  flexDirection={"column"}
                  px={{ base: 4, tablet: "172px", laptop: 4 }}
                >
                  <Heading
                    textAlign={"center"}
                    size={{ base: "sm", tablet: "sm", xl: "md" }}
                  >
                    What State are you in?
                  </Heading>
                  <Box
                    mt={{ base: 10, tablet: 20, lg: "120px" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    gap={4}
                    w={"100%"}
                    flexDirection={{ base: "column", laptop: "row" }}
                  >
                    <Box display={{ base: "none", laptop: "block" }}>
                      <BorderedButton
                        key={1}
                        onClick={() => {
                          setStep(7);
                          updateURL(7);
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                      >
                        <ArrowLeft />
                        <Text ml={"12px"}>Previous</Text>
                      </BorderedButton>
                    </Box>

                    <Flex
                      w={{ base: "100%", laptop: "fit-content" }}
                      gap={2}
                      flexWrap={{ base: "wrap", laptop: "nowrap" }}
                    >
                      {STATE_OPTIONS.map((option, index) => {
                        return (
                          <BorderedButton
                            key={index}
                            onClick={() => {
                              setCurrentState(option.title);
                            }}
                            width={{
                              base: "calc(33% - 8px)",
                              laptop: "84px",
                            }}
                            size={{ base: "mobile", tablet: "mobileLarge" }}
                            className={option.title === state ? "active" : ""}
                          >
                            {option.title}
                          </BorderedButton>
                        );
                      })}
                    </Flex>
                    <Flex
                      mt={6}
                      gap={2}
                      w={"100%"}
                      display={{ base: "flex", laptop: "none" }}
                    >
                      <Box w={"50%"}>
                        <BorderedButton
                          key={1}
                          onClick={() => {
                            setStep(7);
                            updateURL(7);
                          }}
                          size={{ base: "mobile", tablet: "md" }}
                          width={"100%"}
                        >
                          <ArrowLeft />
                          <Text ml={"12px"}>Previous</Text>
                        </BorderedButton>
                      </Box>
                      <Box w={"50%"}>
                        <BorderedButton
                          key={1}
                          onClick={() => {
                            handleSaveState();
                          }}
                          style={{
                            backgroundColor: !state ? "#91BFE9" : "#227ED3",
                          }}
                          size={{ base: "mobile", tablet: "md" }}
                          disabled={false}
                          width="100%"
                        >
                          <Text color={"white"}>Next</Text>
                          <ArrowAxisX rotate={true} color={"#fff"} />
                        </BorderedButton>
                      </Box>
                    </Flex>

                    <Box display={{ base: "none", laptop: "block" }}>
                      <PrimaryButton
                        key={1}
                        onClick={() => {
                          handleSaveState();
                        }}
                        size={{ base: "mobile", xl: "md" }}
                        innerText={"Next"}
                        isDisabled={!state}
                        tooltipVisible={!!state}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {step === 9 && (
                <Box
                  pt={{ base: "14px", tablet: 6 }}
                  pb={{ base: 4, tablet: 10, xl: 20 }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", xl: "452px" }}
                  border={"2px solid #E5EBF5"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  display={"flex"}
                  flexDirection={"column"}
                  px={4}
                >
                  <Heading
                    textAlign={"center"}
                    mt={{ base: 6, tablet: 10 }}
                    size={{ base: "sm", tablet: "md", xl: "md" }}
                  >
                    What is your email address?
                  </Heading>
                  <Heading
                    textAlign={"center"}
                    mt={{ base: 2, tablet: 2 }}
                    size={{ base: "us", tablet: "lm", xl: "lm" }}
                    color={"#808D9E"}
                    maxW={"600px"}
                  >
                    We respect your privacy and will only use this to send
                    through your performance results.
                  </Heading>
                  <Box
                    mt={{ base: 10, tablet: "80px" }}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    display={"flex"}
                    maxW={"1520px"}
                    w={"100%"}
                    gap={4}
                    flexDirection={{ base: "column", tablet: "row" }}
                  >
                    <Box display={{ base: "none", tablet: "block" }}>
                      <BorderedButton
                        key={1}
                        onClick={() => {
                          setStep(8);
                          updateURL(8);
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                      >
                        <ArrowLeft />
                        <Text ml={"12px"}>Previous</Text>
                      </BorderedButton>
                    </Box>

                    <Box w={{ base: "100%", xl: "424px" }}>
                      <Input
                        label="Email"
                        control={control}
                        name={"email"}
                        maxLength={100}
                        trigger={() => trigger("email")}
                        error={errors.email?.message}
                        mbError={{ base: "0", tablet: -7 }}
                      />
                    </Box>

                    <Flex
                      gap={2}
                      w={"100%"}
                      display={{ base: "flex", tablet: "none" }}
                    >
                      <Box w={"50%"}>
                        <BorderedButton
                          key={1}
                          onClick={() => {
                            setStep(8);
                            updateURL(8);
                          }}
                          size={{ base: "mobile", tablet: "md" }}
                          width={"100%"}
                        >
                          <ArrowLeft />
                          <Text ml={"12px"}>Previous</Text>
                        </BorderedButton>
                      </Box>
                      <Box w={"50%"}>
                        <PrimaryButton
                          onClick={() => {
                            trigger("email");
                            handleSaveEmail();
                          }}
                          size={{ base: "mobile", tablet: "md" }}
                          width="100%"
                          tooltipVisible
                          innerText={"Next"}
                          isDisabled={!!errors.email?.message}
                        />
                      </Box>
                    </Flex>

                    <Box display={{ base: "none", tablet: "block" }}>
                      <PrimaryButton
                        onClick={() => {
                          trigger("email");
                          handleSaveEmail();
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                        width="100%"
                        tooltipVisible={!errors.email?.message}
                        innerText={"Next"}
                        isDisabled={!!errors.email?.message}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {step === 10 && (
                <Box
                  pt={{ base: "14px", tablet: 6 }}
                  pb={{ base: 4, tablet: 10, xl: "50px" }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", xl: "452px" }}
                  border={"2px solid #E5EBF5"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  display={"flex"}
                  flexDirection={"column"}
                  px={{ base: 4, tablet: 10 }}
                >
                  <Heading
                    textAlign={"center"}
                    mt={{ base: 6, tablet: "60px" }}
                    size={{ base: "sm", tablet: "md", xl: "md" }}
                  >
                    What&apos;s your mobile number?
                  </Heading>
                  <Heading
                    textAlign={"center"}
                    mt={{ base: 2, tablet: 4 }}
                    size={{ base: "us", tablet: "lm" }}
                    color={"#808D9E"}
                    maxW={"940px"}
                    mb={{ base: 10, tablet: "44px" }}
                  >
                    Based on the information provided, you likely qualify to
                    access alternative funds targeting returns as high as 10%
                    per year, and potentially increase your retirement by
                    hundreds of thousands.
                  </Heading>

                  <Box
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    display={"flex"}
                    maxW={"1520px"}
                    w={"100%"}
                    flexDirection={{ base: "column", tablet: "row" }}
                    gap={4}
                  >
                    <Box display={{ base: "none", tablet: "block" }}>
                      <BorderedButton
                        key={1}
                        onClick={() => {
                          setStep(9);
                          updateURL(9);
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                      >
                        <ArrowLeft />
                        <Text ml={"12px"}>Previous</Text>
                      </BorderedButton>
                    </Box>

                    <Box w={{ base: "100%", tablet: "424px" }}>
                      <Input
                        label="Phone"
                        control={control}
                        name={"phone"}
                        maxLength={100}
                        mask="0499 999 999"
                        trigger={() => trigger("phone")}
                        error={errors.phone?.message}
                        mbError={{ base: "0", tablet: -7 }}
                      />
                    </Box>

                    <Box display={{ base: "none", tablet: "block" }}>
                      <PrimaryButton
                        onClick={() => {
                          handleSendPhone();
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                        width="100%"
                        tooltipVisible={
                          !errors.phone?.message &&
                          phoneRuleAgree &&
                          !!getValues("phone").length
                        }
                        innerText={"Next"}
                        isDisabled={
                          !!errors.phone?.message ||
                          isPhoneSending ||
                          !phoneRuleAgree ||
                          !getValues("phone").length
                        }
                      />
                    </Box>
                  </Box>
                  <Flex
                    cursor={"pointer"}
                    onClick={() => setPhoneRuleAgree(!phoneRuleAgree)}
                    maxW={"800px"}
                    w={"100%"}
                    gap={"10px"}
                    mt={{ base: 4, tablet: "46px" }}
                  >
                    <Flex
                      h={{ base: 5, tablet: "27px" }}
                      minW={{ base: 5, tablet: "27px" }}
                      border={!phoneRuleAgree ? "1.5px solid #808D9E" : "none"}
                      borderRadius={{ base: 8, tablet: "10px" }}
                      bg={phoneRuleAgree ? "accent" : "white"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mt={1}
                    >
                      {phoneRuleAgree && <CheckWhite />}
                    </Flex>
                    <Text fontSize={{ base: "", tablet: "20px" }}>
                      I understand I’ll receive a short 5 minute obligation-free
                      call explaining how to take advantage of these options.
                    </Text>
                  </Flex>
                  <Flex
                    mt={6}
                    gap={2}
                    w={"100%"}
                    display={{ base: "flex", tablet: "none" }}
                  >
                    <Box w={"50%"}>
                      <BorderedButton
                        key={1}
                        onClick={() => {
                          updateURL(9);
                          setStep(9);
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                        width={"100%"}
                      >
                        <ArrowLeft />
                        <Text ml={"12px"}>Previous</Text>
                      </BorderedButton>
                    </Box>
                    <Box w={"50%"}>
                      <PrimaryButton
                        onClick={() => {
                          handleSendPhone();
                        }}
                        size={{ base: "mobile", tablet: "md" }}
                        width="100%"
                        tooltipVisible={!errors.phone?.message}
                        innerText={"Next"}
                        isDisabled={
                          !!errors.phone?.message ||
                          isPhoneSending ||
                          !phoneRuleAgree
                        }
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
              {step === 11 && (
                <Box
                  pt={{ base: "14px", tablet: 6 }}
                  pb={{ base: 4, tablet: 10 }}
                  bg={"white"}
                  borderRadius={"20px"}
                  maxW={"1520px"}
                  w={"100%"}
                  h={{ base: "auto", xl: "452px" }}
                  border={"2px solid #E5EBF5"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  px={4}
                >
                  <Heading
                    textAlign={"center"}
                    size={{ base: "mobile", tablet: "md", xl: "md" }}
                  >
                    OTP Verification
                  </Heading>
                  <Heading
                    textAlign={"center"}
                    mt={{ base: 2, tablet: 2 }}
                    size={{ base: "us", tablet: "lm" }}
                    color={"#808D9E"}
                    maxW={"659px"}
                  >
                    An SMS verification code has been sent to{" "}
                    <Text
                      display={{ base: "block", tablet: "inline-block" }}
                      color="black"
                      m={0}
                      p={0}
                    >
                      {getValues("phone")}
                    </Text>
                    <Text>Please enter this 4 digit code</Text>
                  </Heading>
                  <Text
                    fontSize={{ base: "14px", tablet: "20px" }}
                    lineHeight={{ base: 5, tablet: 7 }}
                    mt={{ base: 6, tablet: 4 }}
                  >
                    Didn’t receive code?{" "}
                    <Text
                      color={"accent"}
                      onClick={() => {
                        setDisabled(true);
                        handleSendPhone();
                      }}
                      as="span"
                      opacity={disabled ? 0.5 : 1}
                      pointerEvents={disabled ? "none" : "all"}
                    >
                      {" "}
                      Resend{" "}
                    </Text>
                    <Text
                      color={"accent"}
                      display={disabled ? "inline" : "none"}
                    >
                      <Text opacity={disabled ? 0.5 : 1} as="span">
                        in{" "}
                      </Text>
                      <Text as="span">{seconds}</Text>
                    </Text>
                  </Text>
                  <Box
                    mt={{ base: 10, tablet: "80px", lg: "52px" }}
                    justifyContent={"flex-start"}
                    display={"flex"}
                    maxW={"1520px"}
                    flexDirection={{ base: "column-reverse", tablet: "row" }}
                    gap={4}
                  >
                    <BorderedButton
                      key={1}
                      onClick={() => {
                        setStep(10);
                        updateURL(10);
                        setDisabled(false);
                      }}
                      width={{ base: "100%", tablet: "fit-content" }}
                      size={{ base: "mobile", tablet: "md" }}
                      disabled={isButtonDisabled}
                    >
                      <ArrowLeft />
                      <Text ml={"12px"}>Previous</Text>
                    </BorderedButton>
                    <Flex
                      mb={{ base: 2, tablet: 0 }}
                      gap={{ base: 2, tablet: 4 }}
                      w={{ base: "100%", tablet: "424px" }}
                      position={"relative"}
                    >
                      <OtpInput
                        containerStyle={{ gap: 8 }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        shouldAutoFocus={false}
                        renderInput={(props) => (
                          <Flex
                            justifyContent={"center"}
                            alignItems={"center"}
                            h={{ base: "70px", tablet: 20 }}
                            w={{ base: "70px", tablet: "78px" }}
                            borderRadius={"20px"}
                            border={"2px solid"}
                            borderColor={otpError ? "negative" : "border"}
                            fontSize={"20px"}
                            lineHeight={7}
                            color={"dark"}
                            gap={2}
                          >
                            <input
                              {...props}
                              style={{ ...props.style, outline: "none" }}
                              placeholder="_"
                            />
                          </Flex>
                        )}
                      />
                      {otpError && (
                        <Text
                          top={"calc(100% + 10px)"}
                          position={"absolute"}
                          fontSize={"14px"}
                          fontWeight={5}
                          color={"negative"}
                        >
                          The code you entered is incorrect. Please try again
                        </Text>
                      )}
                    </Flex>
                  </Box>
                </Box>
              )}
            </Flex>
          </Container>
        </Box>
        <Text
          fontStyle={"italic"}
          fontSize={{ base: "16px", tablet: "24px" }}
          mt={{ base: 8, tablet: "60px" }}
          pb={{ base: 0, tablet: "22px" }}
          mx={"auto"}
          textAlign={"center"}
          maxWidth={{ base: "70%", tablet: "100%" }}
        >
          It takes under 60 seconds and is 100% obligation-free.
        </Text>
      </Box>

      <Footer />
    </>
  );
};

export default CongratulationPage;
