/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Filler,
	LineControllerChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { divideNumberByGrades } from "../../utils/helpers/divideNumberByGrades";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Filler
);

const getOptions = (externalLabels: string[], width: number) => {
	return {
		onHover: (
			event: { native: { target: { style: { cursor: string } } } },
			chartElement: string | any[]
		) => {
			event.native.target.style.cursor = chartElement[0]
				? "pointer"
				: "default";
		},
		tension: 0.5,
		responsive: true,

		maintainAspectRatio: false,
		elements: {
			point: {
				radius: 4,
				borderWidth: 2,
				hoverBorderWidth: 2,
				cursor: "pointer",
				hoverBackgroundColor: "#ffffff",
			},
		},
		scales: {
			x: {
				border: {
					color: "#E5EBF5",
				},
				grid: {
					display: false,
					tickmarklength: 10,
				},
				ticks: {
					padding: 16,
					font: {
						family: "Readex Pro",
						size: width < 1280 ? 12 : 14,
					},
					callback: (value: any, index: any, labels: any) => {
						if (width < 1280) {
							if (index === 0) {
								return externalLabels[0]
							}

							if (index === labels.length - 1) {
								return externalLabels[index];
							}

							return null;
						} else {
							return externalLabels[index];
						}
					},
				},
			},
			y: {
				beginAtZero: true,
				border: {
					width: 0,
				},

				grid: {
					color: "#E5EBF5",
					display: true,
				},
				ticks: {
					padding: 24,
					callback: function (value: number) {
						if (value !== 0) {
							return "$" + divideNumberByGrades(Math.trunc(value));
						} else {
							return "$0,0";
						}
					},
					font: {
						family: "Readex Pro",
						size: width < 1280 ? 12 : 14,
					},
				},
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
				external: function (context: {
					tooltip: any;
					chart: { canvas: { getBoundingClientRect: () => any } };
				}) {
					// Tooltip Element
					let tooltipEl = document.getElementById("chartjs-tooltip");

					// Create element on first render
					if (!tooltipEl) {
						tooltipEl = document.createElement("div");
						tooltipEl.id = "chartjs-tooltip";
						tooltipEl.innerHTML = "<table></table>";
						document.body.appendChild(tooltipEl);
					}

					// Hide if no tooltip
					const tooltipModel = context.tooltip;
					if (tooltipModel.opacity === 0) {
						tooltipEl.style.opacity = "0";
						return;
					}

					// Set caret Position
					tooltipEl.classList.remove("above", "below", "no-transform");
					if (tooltipModel.yAlign) {
						tooltipEl.classList.add(tooltipModel.yAlign);
					} else {
						tooltipEl.classList.add("no-transform");
					}

					function getBody(bodyItem: { lines: any }) {
						return bodyItem.lines;
					}

					// Set Text
					if (tooltipModel.body) {
						const bodyLines = tooltipModel.body.map(getBody);

						let innerHtml = "<tbody>";

						bodyLines.forEach(function (body: string, i: string | number) {
							const clearStringValue = body[0].replace(/[^0-9.,]/g, "");
							const value = divideNumberByGrades(Math.floor(
								parseFloat(clearStringValue.replace(/,/g, ""))
							));
							const colors = tooltipModel.labelColors[i];
							let style = "background:" + colors.backgroundColor;
							style += "; border-color: #E5EBF5";
							style += "; border-width: 1px";
							style += "; font-size: 14px";
							style += "; border-radius: 8px";
							style += "; box-shadow: 0px 0px 40px 0px #CCD1D780";
							style += `; ${
								i === bodyLines.length - 1
									? "transform: translate(-50%, -120%)"
									: "transform: translate(-50%, -220%)"
							}`;
							style += "; padding: 10px";
							const div =
								'<div style="' + style + '">' + "$" + value + "</div>";
							innerHtml += "<tr><td>" + div + "</td></tr>";
						});
						innerHtml += "</tbody>";

						const tableRoot = tooltipEl.querySelector("table");
						tableRoot!.innerHTML = innerHtml;
					}

					const position = context.chart.canvas.getBoundingClientRect();
					// const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

					// Display, position, and set styles for font
					tooltipEl.style.opacity = "1";
					tooltipEl.style.position = "absolute";
					tooltipEl.style.left =
						position.left + window.scrollX + tooltipModel.caretX + "px";
					tooltipEl.style.top =
						position.top + window.scrollY + tooltipModel.caretY + "px";

					tooltipEl.style.padding =
						tooltipModel.padding + "px " + tooltipModel.padding + "px";
					tooltipEl.style.pointerEvents = "none";
				},
			},
		},
	};
};

const Chart = ({
	datasets,
	labels,
	legend,
}: {
	datasets: {
		label: string;
		data: number[];
		backgroundColor: (context: {
			chart: { chartArea: any; ctx: any; data?: unknown };
		}) => any;
		borderColor: string;
		fill: boolean;
		pointBackgroundColor: string;
		pointRadius?: number | number[];
	}[];
	labels: string[];
	legend: { title: string; color: string }[];
}) => {
	const { width } = useWindowSize();
	const data = {
		labels,
		datasets,
	};

	return (
		<Box
			pr={{ base: 0, tablet: 2 }}
			pt={{ base: 0, tablet: 6 }}
			pl={{ base: 0, tablet: 6 }}
			pb={{ base: 2, tablet: 6 }}
			h={"542px"}
			bg={"white"}
			border={"2px solid"}
			borderColor={"border"}
			borderRadius={"20px"}
			position={"relative"}
			mt={{ base: 2, tablet: 4 }}
		>
			<Flex
				flexDir={{ base: "column", xl: "row" }}
				justifyContent={{ base: "flex-start", xl: "center" }}
				alignItems={{ base: "flex-start", xl: "center" }}
				gap={{ base: 1, tablet: 2, xl: 10 }}
				p={{ base: 4, tablet: "0 0 0 8px" }}
				pb={0}
			>
				{legend.map((i, index) => {
					return (
						<Flex
							mb={-1}
							fontSize={{ base: "12px", tablet: "14px" }}
							lineHeight={5}
							letterSpacing={"-0.02em"}
							key={index}
							alignItems={"center"}
							gap={2}
						>
							<Box
								borderRadius={"100%"}
								h={3}
								w={3}
								border={"2px solid"}
								borderColor={i.color}
							/>
							<Text>{i.title}</Text>
						</Flex>
					);
				})}
			</Flex>
			<Box height={"480px"}>
				<Line
					className="lineChart"
					options={
						getOptions(
							data.labels,
							width
						) as unknown as LineControllerChartOptions
					}
					data={data}
				/>
			</Box>
		</Box>
	);
};

export default Chart;
