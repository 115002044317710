const StarIco = () => {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.57658 2.70419C5.20991 1.56806 5.52657 1 6 1C6.47343 1 6.79009 1.56806 7.42342 2.70419L7.58727 2.99812C7.76724 3.32097 7.85722 3.48239 7.99753 3.5889C8.13783 3.69541 8.31257 3.73495 8.66206 3.81402L8.98023 3.88601C10.2101 4.16428 10.825 4.30341 10.9713 4.77387C11.1176 5.24432 10.6984 5.73454 9.85995 6.71496L9.64304 6.96861C9.40479 7.24722 9.28566 7.38652 9.23207 7.55886C9.17848 7.73119 9.19649 7.91706 9.23251 8.28878L9.2653 8.6272C9.39206 9.9353 9.45544 10.5894 9.07243 10.8801C8.68941 11.1709 8.11366 10.9058 6.96216 10.3756L6.66426 10.2384C6.33704 10.0878 6.17343 10.0124 6 10.0124C5.82657 10.0124 5.66296 10.0878 5.33574 10.2384L5.03784 10.3756C3.88634 10.9058 3.31059 11.1709 2.92757 10.8801C2.54456 10.5894 2.60794 9.9353 2.7347 8.6272L2.76749 8.28878C2.80351 7.91706 2.82152 7.73119 2.76793 7.55886C2.71434 7.38652 2.59521 7.24722 2.35696 6.96861L2.14005 6.71496C1.30162 5.73454 0.882412 5.24432 1.02871 4.77387C1.17501 4.30341 1.78993 4.16428 3.01977 3.88601L3.33794 3.81402C3.68743 3.73495 3.86217 3.69541 4.00247 3.5889C4.14278 3.48239 4.23276 3.32097 4.41273 2.99812L4.57658 2.70419Z"
				fill="#EDA52F"
			/>
		</svg>
	);
};

export default StarIco;
