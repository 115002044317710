import React from "react";

const LoadingIcon = () => {
	return (
		<svg
			className="loading-ico"
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.3 16C27.9627 16 28.5062 16.5386 28.4401 17.198C28.2444 19.1481 27.5732 21.0272 26.4776 22.6668C25.1591 24.6402 23.2849 26.1783 21.0922 27.0866C18.8995 27.9948 16.4867 28.2324 14.1589 27.7694C11.8311 27.3064 9.69295 26.1635 8.01472 24.4853C6.33649 22.807 5.1936 20.6689 4.73058 18.3411C4.26755 16.0133 4.50519 13.6005 5.41345 11.4078C6.3217 9.21508 7.85977 7.34094 9.83316 6.02236C11.4728 4.92676 13.3519 4.2556 15.302 4.05995C15.9614 3.99378 16.5 4.53726 16.5 5.2C16.5 5.86274 15.9607 6.39227 15.3031 6.4749C13.8285 6.66019 12.4113 7.18615 11.1665 8.01789C9.58782 9.07275 8.35736 10.5721 7.63076 12.3262C6.90416 14.0804 6.71404 16.0106 7.08446 17.8729C7.45488 19.7351 8.36919 21.4456 9.71177 22.7882C11.0544 24.1308 12.7649 25.0451 14.6271 25.4155C16.4893 25.786 18.4196 25.5958 20.1738 24.8692C21.9279 24.1426 23.4272 22.9122 24.4821 21.3335C25.3138 20.0887 25.8398 18.6715 26.0251 17.1969C26.1077 16.5393 26.6373 16 27.3 16Z"
				fill="#CCD2D8"
			/>
		</svg>
	);
};

export default LoadingIcon;
