import React from "react";

const CheckWhite = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3734 0.959397C11.764 1.34992 11.764 1.98309 11.3734 2.37361L4.70678 9.04028C4.31626 9.4308 3.68309 9.4308 3.29257 9.04028L0.625901 6.37361C0.235377 5.98309 0.235377 5.34992 0.625901 4.9594C1.01643 4.56887 1.64959 4.56887 2.04011 4.9594L3.99967 6.91896L6.97945 3.93918L9.95923 0.959397C10.3498 0.568873 10.9829 0.568873 11.3734 0.959397Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckWhite;
