import { FC } from "react";
import { ArrowAxisX } from "../../assets/icons/ArrowAxisX";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { EnterArrowIcon } from "../../assets/icons/EnterArrowIcon";

interface ButtonProps {
	innerText: string | React.ReactNode;
	size?: { [key: string]: string } | "md";
	onClick?: () => void;
	tooltipVisible?: boolean;
	isDisabled?: boolean;
	width?: string;
	type?: "submit" | "button";
}

export const PrimaryButton: FC<ButtonProps> = ({
	innerText,
	onClick,
	tooltipVisible = false,
	isDisabled = false,
	width = "auto",
	type = "submit",
	size = "md",
}) => {
	return (
		<Flex
			w={width}
			flexDirection={"column"}
			alignItems={"center"}
			position={"relative"}
			h={"fit-content"}
		>
			<Button
				w={width}
				type={type}
				isDisabled={isDisabled}
				rightIcon={<ArrowAxisX color="#ffffff" />}
				variant={"primary"}
				size={size}
				onClick={onClick}
			>
				{innerText}
			</Button>
			{tooltipVisible && (
				<Flex
					display={{ base: "none", xl: "flex" }}
					opacity={isDisabled ? 0.3 : 1}
					position={"absolute"}
					top={"calc(100% + 10px)"}
					justifyContent={"center"}
				>
					<Text mr={1} color={"accent"} fontWeight={300} size={"sm"}>
						press
					</Text>
					<Text color={"accent"} size={"sm"}>
						Enter
					</Text>
					<EnterArrowIcon />
				</Flex>
			)}
		</Flex>
	);
};
