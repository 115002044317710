import { StyleConfig, extendTheme } from "@chakra-ui/react";

const theme: StyleConfig = extendTheme({
  breakpoints: {
    tablet: "834px",
    xl: "1280px",
    laptop: "1440px",
    "3xl": "1680px",
  },
  colors: {
    // Primary
    dark: "#001C3D",
    grey: "#808D9E",
    blue: "#F1F6FF",
    lightBlue: "#F8FAFF",
    white: "#FFFFFF",
    // Secondary
    accent: "#227ED3",
    accentHover: "#106ABE",
    accentDisabled: "#91BFE9",
    negative: "#D21212",
    warning: "#D85C03",
    success: "#00A023",
    // Border
    border: "#E5EBF5",
  },
  fonts: {
    heading: `"Readex Pro", sans-serif`,
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: "accent",
          color: "white",
          _hover: {
            bg: "accentHover",
          },
          _disabled: {
            bg: "accentDisabled",
            pointerEvents: "none",
          },
        },
        bordered: {
          bg: "white",
          color: "dark",
          border: "2px solid #E5EBF5",
          "&.active": {
            bg: "lightBlue",
            border: "2px solid #227ED3",
          },

          _hover: {
            bg: "lightBlue",
            border: "2px solid #227ED3",
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: "none",
          },
        },
      },
      sizes: {
        sm: {
          height: 20,
          borderRadius: "20px",
          minWidth: "80px",
        },
        md: {
          height: 20,
          minWidth: "fit-content",
          paddingLeft: "32px",
          paddingRight: "32px",
          borderRadius: "20px",
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: 500,
        },
        mobile: {
          height: "64px",
          minWidth: "fit-content",
          borderRadius: "20px",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 500,
        },
        mobileLarge: {
          height: "80px",
          minWidth: "fit-content",
          borderRadius: "20px",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 500,
        },
      },
    },
    Heading: {
      baseStyles: {
        fontFamily: "initial",
      },
      sizes: {
        us: {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 400
        },
        xsm: {
          fontSize: "16px",
          lineHeight: "25.2px",
          fontWeight: 400
        },
        xs: {
          fontSize: "18px",
          lineHeight: "25.2px",
          fontWeight: 400
        },
        lg: {
          fontSize: "50px",
          lineHeight: "64px",
          fontWeight: 500,
        },
        md: {
          fontSize: "32px",
          lineHeight: "44px",
          fontWeight: 500,
        },
        sm: {
          fontSize: "24px",
          lineHeight: "34px",
          fontWeight: 400,
        },
        lm: {
          fontSize: "20px",
          lineHeight: "28px",
          fontWeight: 400,
        },
        mobile: {
          fontSize: "28px",
          lineHeight: "36.4px",
          fontWeight: 500,
        },
        fit: {
          fontSize: "24px",
          lineHeight: "31.2px",
          fontWeight: 500,
        }
      },
    },
    Input: {
      baseStyle: {
        field: {
          height: 20,
          minWidth: "155px",
          borderRadius: "20px",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 400,
          border: "2px solid #E5EBF5",
          padding: "26px 32px",
          _hover: {
            border: "2px solid",
            "border-color": "#E5EBF5!important",
          },
          "&:focus-visible": {
            border: "2px solid",
            borderColor: "#227ED3!important",
            boxShadow: "none!important",
          },
        },
      },
    },
    Text: {
      sizes: {
        xs: {
          fontSize: "18px",
          lineHeight: "25.2px",
          fontWeight: 400
        },
        sm: {
          fontSize: "14px",
          lineHeight: "20px",
        },
        md: {
          fontSize: "32px",
          lineHeight: "44px",
        },
        mobile: {
          fontSize: "24px",
          lineHeight: "34px",
          fontWeight: 400,
        },
      },
    },
  },
});

export default theme;
