import { FC } from "react";

interface InfoIconProps {
    fill?: string,
    color?: string, 
    opacity?: number
}

const InfoIcon:FC<InfoIconProps> = ({fill = "#001C3D", color = "#001C3D", opacity = 0.2}) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity={opacity}>
				<path
					d="M29.3337 16.0003C29.3337 23.3641 23.3641 29.3337 16.0003 29.3337C8.63653 29.3337 2.66699 23.3641 2.66699 16.0003C2.66699 8.63653 8.63653 2.66699 16.0003 2.66699C23.3641 2.66699 29.3337 8.63653 29.3337 16.0003Z"
					fill={fill}
				/>
				<path
					d="M29.3337 16.0003C29.3337 23.3641 23.3641 29.3337 16.0003 29.3337C8.63653 29.3337 2.66699 23.3641 2.66699 16.0003C2.66699 8.63653 8.63653 2.66699 16.0003 2.66699C23.3641 2.66699 29.3337 8.63653 29.3337 16.0003Z"
					fill="black"
					fillOpacity="0.2"
				/>
			</g>
			<path
				d="M16.0003 23.6663C16.5526 23.6663 17.0003 23.2186 17.0003 22.6663V14.6663C17.0003 14.1141 16.5526 13.6663 16.0003 13.6663C15.448 13.6663 15.0003 14.1141 15.0003 14.6663V22.6663C15.0003 23.2186 15.448 23.6663 16.0003 23.6663Z"
				fill={color}
			/>
			<path
				d="M16.0003 23.6663C16.5526 23.6663 17.0003 23.2186 17.0003 22.6663V14.6663C17.0003 14.1141 16.5526 13.6663 16.0003 13.6663C15.448 13.6663 15.0003 14.1141 15.0003 14.6663V22.6663C15.0003 23.2186 15.448 23.6663 16.0003 23.6663Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M16.0003 9.33301C16.7367 9.33301 17.3337 9.92996 17.3337 10.6663C17.3337 11.4027 16.7367 11.9997 16.0003 11.9997C15.2639 11.9997 14.667 11.4027 14.667 10.6663C14.667 9.92996 15.2639 9.33301 16.0003 9.33301Z"
				fill={color}
			/>
			<path
				d="M16.0003 9.33301C16.7367 9.33301 17.3337 9.92996 17.3337 10.6663C17.3337 11.4027 16.7367 11.9997 16.0003 11.9997C15.2639 11.9997 14.667 11.4027 14.667 10.6663C14.667 9.92996 15.2639 9.33301 16.0003 9.33301Z"
				fill="black"
				fillOpacity="0.2"
			/>
		</svg>
	);
};

export default InfoIcon;
