import { Button } from "@chakra-ui/react";
import { Properties } from "csstype";
import { FC, ReactNode } from "react";

interface BorderedButtonProps {
  children: ReactNode | ReactNode[];
  onClick: () => void;
  style?: React.CSSProperties | undefined;
  size?: "sm" | "md" | { [key: string]: string };
  disabled?: boolean;
  width?: string | { [key: string]: string };
  className?: string;
  display?: string | { [key: string]: string };
}

export const BorderedButton: FC<BorderedButtonProps> = ({
  children,
  onClick,
  style,
  size = "sm",
  disabled,
  width = "auto",
  className = "",
  display,
}) => {
  return (
    <Button
      display={display}
      className={className}
      w={width}
      isDisabled={disabled}
      style={style}
      onClick={onClick}
      variant={"bordered"}
      size={size}
    >
      {children}
    </Button>
  );
};
