import { Box, Container, Divider, Flex, Text } from "@chakra-ui/react";
import { CompanyTitle } from "../companyTitle/CompanyTitle";
import { Link } from "react-router-dom";
import { getCompanyNameByDomain } from "../../utils/helpers/getCompanyNameByDomain";
import { useState } from "react";

const getFooterCopyByDomain = (
  companyName: string,
  setOpen: () => void,
  isOpen: boolean
) => {
  const hostname = window.location.hostname;

  switch (hostname) {
    case "iwg.superstrategyreview.com.au":
      return isOpen ? (
        <>
          <Text>
            superstrategyreview.com.au is owned and operated by AMBA Solutions
            Pty Ltd (ABN: 74 623 403 190) | (we, us, AMBA) pass your information
            to authorised financial advisers, Integral Wealth Group Pty Ltd who
            will contact you to perform a superannuation review. Financial
            planning products & services offered by Integral Wealth Group Pty
            Ltd (ABN: 44 632 978 173). Integral Wealth Group Pty Ltd is a
            Corporate Authorised Representative (No 1276797) of Next Generation
            Advice Pty Ltd AFSL 302947 (The Licensee).{" "}
            {!isOpen && (
              <Text
                onClick={setOpen}
                textDecoration={"underline"}
                as={"span"}
                cursor={"pointer"}
              >
                Read more
              </Text>
            )}
          </Text>
          <Text>
            By providing your details, you consent to AMBA supplying your
            information to Integral Wealth Group Pty Ltd to receive a call, SMS
            or email about your superannuation review. Please refer to our
            Privacy Policy for further information about how we handle your
            information. You are under no obligation to accept the
            superannuation review or engage a financial adviser referred to you
            via superstrategyreview.com.au. AMBA introduces consumers to
            financial advisers. AMBA does not provide direct superannuation
            comparisons between funds or a performance review of your fund.
            Superstrategyreview.com.au will show indicative retirement balances
            as rough projections based on the following assumptions: Average
            retirement age of 67, average balanced fund return of 6%pa,
            Superannuation guarantee of 11%pa with no extra contributions, fund
            fixed fees of $80/month & annual fees of 1.1% and the average
            lifespan post retirement of 25 years. A 15% tax rate has been
            applied to both contributions & interest earned on a monthly basis.
          </Text>
          <Text>
            The indicative results should in no way be considered factual,
            accurate or reliable, as they don’t take into account your fund,
            structure or setup. They are purely indicative estimations based on
            the assumptions listed above. AMBA does not provide financial
            product advice or dealing services, nor does AMBA promote financial
            products or product issuers. We endeavour to pass your enquiry on to
            financial advisers who can offer you a comparison of a wide range of
            products, providers and services and a review of your superannuation
            fund’s performance. Information on this website is for general
            informational purposes and not tailored to individual needs. You
            should, before engaging any service provider, consider whether the
            service provider is appropriate for you in the context of your
            personal and financial circumstances and goals. You should consider
            the service provider’s disclosure documents, such as the financial
            services guide and/or product disclosure statement (PDS), before you
            engage the provider, to assess if the advice suits your financial
            situation and goals.
          </Text>
          <Text>
            If you decide to proceed with a service offering after a referral
            from superstrategyreview.com.au, you will be dealing directly with
            the service provider and not with AMBA. AMBA does not play a role in
            the provision of advice. AMBA is not responsible for services
            provided by third party service providers and, to the maximum extent
            permitted by law, is not liable for any loss or damage suffered as a
            result of the use or reliance on services provided by those third
            party service providers. AMBA does not make any representation as to
            the quality or suitability of the financial product advice provided
            by any financial adviser to whom it refers users of this website.
            AMBA Solutions may earn commissions on referrals; the amount will be
            disclosed by Integral Wealth Group Pty Ltd through their onboarding
            process.{" "}
            <Text
              onClick={setOpen}
              textDecoration={"underline"}
              as={"span"}
              cursor={"pointer"}
            >
              Read less
            </Text>
          </Text>
        </>
      ) : (
        <Text>
          superstrategyreview.com.au is owned and operated by AMBA Solutions Pty
          Ltd (ABN: 74 623 403 190) | (we, us, AMBA) pass your information to
          authorised financial advisers, Integral Wealth Group Pty Ltd who will
          contact you to perform a superannuation review. Financial planning
          products & services offered by Integral Wealth Group Pty Ltd (ABN: 44
          632 978 173). Integral Wealth Group Pty Ltd is a Corporate Authorised
          Representative (No 1276797) of Next Generation Advice Pty Ltd AFSL
          302947 (The Licensee).{" "}
          <Text
            onClick={setOpen}
            textDecoration={"underline"}
            as={"span"}
            cursor={"pointer"}
          >
            Read more
          </Text>
        </Text>
      );

    default:
      return isOpen ? (
        <>
          <Text>
            superstrategyreview.com.au is owned and operated by AMBA Solutions
            Pty Ltd (ABN: 74 623 403 190) | (we, us, AMBA) pass your information
            to authorised financial advisers, {companyName} who will contact you
            to perform a superannuation review. Financial planning products &
            services offered by ITM Global Pty Ltd T/As {companyName} (ABN: 13
            610 921 205). {companyName} is a Corporate Authorised Representative
            (No 001304365) of Australia National Investment Group Pty Ltd AFSL
            522028 (The Licensee).{" "}
          </Text>

          <Text>
            By providing your details, you consent to AMBA supplying your
            information to {companyName} to receive a call, SMS or email about
            your superannuation review. Please refer to our Privacy Policy for
            further information about how we handle your information. You are
            under no obligation to accept the superannuation review or engage a
            financial adviser referred to you via superstrategyreview.com.au.
            AMBA introduces consumers to financial advisers. AMBA does not
            provide direct superannuation comparisons between funds or a
            performance review of your fund. Superstrategyreview.com.au will
            show indicative retirement balances as rough projections based on
            the following assumptions: Average retirement age of 67, average
            balanced fund return of 6%pa, Superannuation guarantee of 11%pa with
            no extra contributions, fund fixed fees of $80/month & annual fees
            of 1.1% and the average lifespan post retirement of 25 years. A 15%
            tax rate has been applied to both contributions & interest earned on
            a monthly basis.{" "}
          </Text>

          <Text>
            The indicative results should in no way be considered factual,
            accurate or reliable, as they don’t take into account your fund,
            structure or setup. They are purely indicative estimations based on
            the assumptions listed above. AMBA does not provide financial
            product advice or dealing services, nor does AMBA promote financial
            products or product issuers. We endeavour to pass your enquiry on to
            financial advisers who can offer you a comparison of a wide range of
            products, providers and services and a review of your superannuation
            fund’s performance. Information on this website is for general
            informational purposes and not tailored to individual needs.You
            should, before engaging any service provider, consider whether the
            service provider is appropriate for you in the context of your
            personal and financial circumstances and goals. You should consider
            the service provider’s disclosure documents, such as the financial
            services guide and/or product disclosure statement (PDS), before you
            engage the provider, to assess if the advice suits your financial
            situation and goals.
          </Text>

          <Text>
            If you decide to proceed with a service offering after a referral
            from superstrategyreview.com.au, you will be dealing directly with
            the service provider and not with AMBA. AMBA does not play a role in
            the provision of advice. AMBA is not responsible for services
            provided by third party service providers and, to the maximum extent
            permitted by law, is not liable for any loss or damage suffered as a
            result of the use or reliance on services provided by those third
            party service providers. AMBA does not make any representation as to
            the quality or suitability of the financial product advice provided
            by any financial adviser to whom it refers users of this website.
            AMBA Solutions may earn commissions on referrals; the amount will be
            disclosed by {companyName} through their onboarding process.{" "}
            <Text
              onClick={setOpen}
              textDecoration={"underline"}
              as={"span"}
              cursor={"pointer"}
            >
              Read less
            </Text>
          </Text>
        </>
      ) : (
        <Text>
          superstrategyreview.com.au is owned and operated by AMBA Solutions Pty
          Ltd (ABN: 74 623 403 190) | (we, us, AMBA) pass your information to
          authorised financial advisers, {companyName} who will contact you to
          perform a superannuation review. Financial planning products &
          services offered by ITM Global Pty Ltd T/As {companyName} (ABN: 13 610
          921 205). {companyName} is a Corporate Authorised Representative (No
          001304365) of Australia National Investment Group Pty Ltd AFSL 522028
          (The Licensee).{" "}
          <Text
            onClick={setOpen}
            textDecoration={"underline"}
            as={"span"}
            cursor={"pointer"}
          >
            Read more
          </Text>
        </Text>
      );
  }
};

export const Footer = () => {
  const companyName = getCompanyNameByDomain();
  const [open, setOpen] = useState(false);
  return (
    <Box
      px={{ base: 4, tablet: 10, xl: "80px" }}
      mt={"auto"}
      bg={"border"}
      py={10}
      as="section"
    >
      <Container p={0} maxW={"1520px"}>
        <Flex
          flexDirection={{ base: "column", tablet: "row" }}
          alignItems={{ base: "start", tablet: "center" }}
          mb={6}
          gap={{ base: 6, tablet: 0 }}
          justifyContent={"space-between"}
          color={"grey"}
        >
          <Box>
            <Text size={"sm"}>
              Level 3, 360 Kent St, Sydney <br />
              NSW, 2000
            </Text>
          </Box>

          <Flex
            letterSpacing={"-0.01em"}
            fontSize={"14px"}
            lineHeight={"20px"}
            color={"grey"}
            gap={10}
          >
            <Text letterSpacing={"-0.01em"}>
              info@superstrategyreview.com.au
            </Text>
            <Text letterSpacing={"-0.06em"}>1800 879 143</Text>
          </Flex>
        </Flex>
        <Flex
          gap={{ base: 6, tablet: 0 }}
          flexDir={{ base: "column", tablet: "row" }}
          justifyContent={"flex-end"}
          color={"grey"}
          mb={10}
        >
          <Flex gap={4}>
            <Text
              _hover={{ color: "dark" }}
              textDecoration={"underline"}
              size={"sm"}
            >
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Text>
            <Text
              _hover={{ color: "dark" }}
              textDecoration={"underline"}
              size={"sm"}
            >
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </Text>
          </Flex>
        </Flex>
      </Container>
      <Divider h={"2px"} bg={"#0016310D"} />
      <Container p={0} maxW={"1520px"} pt={10}>
        <Flex
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={300}
          color={"grey"}
          flexDirection={"column"}
          gap={5}
          overflow={"hidden"}
        >
          {getFooterCopyByDomain(companyName, () => setOpen(!open), open)}
        </Flex>
      </Container>
    </Box>
  );
};
