import { Text, Flex, Heading } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { getQuizTitle } from "../../utils/hooks/getQuizTitle";
import { StepEnum } from "../../utils/types/StepEnum";
import LoadingIcon from "../../assets/icons/LoadingIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const LoadingCircle = ({ delay, text }: { delay: number; text: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      //   if (delay === 6000) {
      //     setTimeout(() => {
      //       calculationData.is_qualified ? navigate("/step-8") : navigate("/dnq");
      //     }, 2000);
      //   }
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <Flex>
      <Flex alignItems={"center"} gap={{ base: 4, lg: "2px" }}>
        {isLoading ? <LoadingIcon /> : <CheckIcon />}
        <Text
          fontSize={{ base: "18px", tablet: "20px" }}
          color={isLoading ? "grey" : "dark"}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

const CalculatingResults = () => {
  const navigate = useNavigate();
  const dataForCalculation = {
    super_balance: Number(sessionStorage.getItem("FIRST_STEP_RANGE_VALUE_KEY")),
    age: Number(sessionStorage.getItem("age")),
    income: Number(sessionStorage.getItem("SIXTH_STEP_RANGE_VALUE_KEY")),
    desired_weekly_retirement_income: Number(
      sessionStorage.getItem("valuePerWeek")
    ),
    super_fund_type: sessionStorage.getItem("SUPER_FUND"),
    employment_status: sessionStorage.getItem("E_STATUS"),
  };
  useEffect(() => {
    axios
      .post(
        "https://api.superstrategyreview.com.au/api/superannuation/calculate",
        {
          super_balance: dataForCalculation.super_balance,
          age: dataForCalculation.age,
          income: dataForCalculation.income,
          desired_weekly_retirement_income:
            dataForCalculation.desired_weekly_retirement_income,
          super_fund_type: dataForCalculation.super_fund_type,
          employment_status: dataForCalculation.employment_status,
        }
      )
      .then(function (response) {
        sessionStorage.setItem(
          "CALCULATION_DATA",
          JSON.stringify(response.data.data)
        );
        setTimeout(() => {
          if (response.data.data.is_qualified) {
            navigate("/step-7");
            sessionStorage.setItem("STEP_KEY", "7");
          } else {
            navigate("/dnq");
          }
        }, 6000);
      })
      .catch(function (error) {
        console.error(error);
      });
  });

  const quizTitle = getQuizTitle(StepEnum.CALCULATING);
  return (
    <Flex
      pt={{ base: 2, tablet: 4, xl: "124px" }}
      pb={{ base: 4, tablet: 0 }}
      flexDirection={"column"}
      alignItems={"center"}
      h={"100%"}
      w={"100%"}
      gap={{ base: "72px", tablet: 10 }}
    >
      <Heading size={{ base: "sm", xl: "md" }} textAlign={"center"}>
        {quizTitle}
      </Heading>
      <Flex flexDir={{ base: "column", xl: "row" }} gap={{ base: 8, xl: 10 }}>
        <LoadingCircle delay={2000} text="Analysing Answers" />
        <LoadingCircle delay={4000} text="Estimating Projections" />
        <LoadingCircle delay={6000} text="Checking Eligibility" />
      </Flex>
    </Flex>
  );
};

export default CalculatingResults;
