import React from "react";

const Logo = () => {
	return (
		<svg
			width={"100%"}
			height={"100%"}
			viewBox="0 0 268 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.9993 32C25.8359 32 32.9993 24.8366 32.9993 16C32.9993 7.16344 25.8359 0 16.9993 0C8.16276 0 0.999311 7.16344 0.999311 16C0.999311 19.8237 2.34057 23.334 4.57826 26.0863L0.521371 30.1432C-0.0644153 30.729 -0.0644154 31.6788 0.521371 32.2645C1.10716 32.8503 2.0569 32.8503 2.64269 32.2645L6.67961 28.2276C9.46552 30.5812 13.0667 32 16.9993 32Z"
				fill="url(#paint0_linear_656_8443)"
			/>
			<circle cx="17" cy="16" r="13.5" stroke="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.7225 8H18.3891V9.61132C18.9915 9.70073 19.5166 9.86289 19.9644 10.0978C20.6992 10.4771 21.2859 11.0104 21.7244 11.6978L20.0178 13.3689C19.6503 12.8593 19.2296 12.4741 18.7555 12.2134C18.2815 11.9526 17.7718 11.8222 17.2267 11.8222C16.8474 11.8222 16.5096 11.8756 16.2133 11.9822C15.9289 12.0771 15.7096 12.2252 15.5555 12.4267C15.4015 12.6163 15.3244 12.8415 15.3244 13.1022C15.3244 13.3156 15.3778 13.5052 15.4844 13.6711C15.5911 13.8371 15.7392 13.9852 15.9289 14.1156C16.1304 14.2341 16.3733 14.3408 16.6578 14.4356C16.9422 14.5304 17.2563 14.6074 17.6 14.6667C18.2518 14.797 18.8385 14.9748 19.36 15.2C19.8815 15.4133 20.3259 15.6741 20.6933 15.9822C21.0726 16.2904 21.3629 16.6459 21.5644 17.0489C21.7659 17.4519 21.8666 17.9022 21.8666 18.4C21.8666 19.2415 21.6652 19.9585 21.2622 20.5511C20.8592 21.1437 20.3022 21.6 19.5911 21.92C19.2223 22.0859 18.8217 22.2089 18.3891 22.2887V24H15.7225V22.2895C15.1313 22.1884 14.5956 22.0178 14.1155 21.7778C13.2978 21.3511 12.5926 20.7348 12 19.9289L13.6711 18.0444C14.2518 18.8504 14.8267 19.4015 15.3955 19.6978C15.9763 19.9822 16.5985 20.1244 17.2622 20.1244C17.6296 20.1244 17.9555 20.0711 18.24 19.9644C18.5363 19.8578 18.7674 19.7096 18.9333 19.52C19.1111 19.3304 19.2 19.1111 19.2 18.8622C19.2 18.6726 19.1585 18.5007 19.0755 18.3467C18.9926 18.1807 18.8741 18.0385 18.72 17.92C18.5659 17.7896 18.3822 17.677 18.1689 17.5822C17.9555 17.4756 17.7185 17.3867 17.4578 17.3156C17.197 17.2444 16.9126 17.1793 16.6044 17.12C15.9407 16.9778 15.36 16.7941 14.8622 16.5689C14.3644 16.3437 13.9496 16.0711 13.6178 15.7511C13.2859 15.4311 13.037 15.0637 12.8711 14.6489C12.717 14.2222 12.64 13.7482 12.64 13.2267C12.64 12.4919 12.8474 11.8519 13.2622 11.3067C13.677 10.7497 14.2281 10.3171 14.9155 10.0089C15.1744 9.8884 15.4434 9.79057 15.7225 9.71545V8Z"
				fill="white"
			/>
			<path
				d="M47.3611 24.1999C46.0544 24.1999 44.9344 23.9666 44.0011 23.4999C43.0811 23.0199 42.2878 22.3266 41.6211 21.4199L43.5011 19.2999C44.1544 20.2066 44.8011 20.8266 45.4411 21.1599C46.0944 21.4799 46.7944 21.6399 47.5411 21.6399C47.9544 21.6399 48.3211 21.5799 48.6411 21.4599C48.9744 21.3399 49.2344 21.1732 49.4211 20.9599C49.6211 20.7466 49.7211 20.4999 49.7211 20.2199C49.7211 20.0066 49.6744 19.8132 49.5811 19.6399C49.4878 19.4532 49.3544 19.2932 49.1811 19.1599C49.0078 19.0132 48.8011 18.8866 48.5611 18.7799C48.3211 18.6599 48.0544 18.5599 47.7611 18.4799C47.4678 18.3999 47.1478 18.3266 46.8011 18.2599C46.0544 18.0999 45.4011 17.8932 44.8411 17.6399C44.2811 17.3866 43.8144 17.0799 43.4411 16.7199C43.0678 16.3599 42.7878 15.9466 42.6011 15.4799C42.4278 14.9999 42.3411 14.4666 42.3411 13.8799C42.3411 13.0532 42.5744 12.3332 43.0411 11.7199C43.5078 11.0932 44.1278 10.6066 44.9011 10.2599C45.6744 9.89991 46.5278 9.71991 47.4611 9.71991C48.7278 9.71991 49.7678 9.93325 50.5811 10.3599C51.4078 10.7866 52.0678 11.3866 52.5611 12.1599L50.6411 14.0399C50.2278 13.4666 49.7544 13.0332 49.2211 12.7399C48.6878 12.4466 48.1144 12.2999 47.5011 12.2999C47.0744 12.2999 46.6944 12.3599 46.3611 12.4799C46.0411 12.5866 45.7944 12.7532 45.6211 12.9799C45.4478 13.1932 45.3611 13.4466 45.3611 13.7399C45.3611 13.9799 45.4211 14.1932 45.5411 14.3799C45.6611 14.5666 45.8278 14.7332 46.0411 14.8799C46.2678 15.0132 46.5411 15.1332 46.8611 15.2399C47.1811 15.3466 47.5344 15.4332 47.9211 15.4999C48.6544 15.6466 49.3144 15.8466 49.9011 16.0999C50.4878 16.3399 50.9878 16.6332 51.4011 16.9799C51.8278 17.3266 52.1544 17.7266 52.3811 18.1799C52.6078 18.6332 52.7211 19.1399 52.7211 19.6999C52.7211 20.6466 52.4944 21.4532 52.0411 22.1199C51.5878 22.7866 50.9611 23.2999 50.1611 23.6599C49.3611 24.0199 48.4278 24.1999 47.3611 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M58.6783 24.1999C57.9183 24.1999 57.2583 24.0332 56.6983 23.6999C56.1383 23.3532 55.7049 22.8666 55.3983 22.2399C55.1049 21.5999 54.9583 20.8399 54.9583 19.9599V13.4199H57.7983V19.4399C57.7983 20.1866 57.9716 20.7732 58.3183 21.1999C58.6783 21.6132 59.1716 21.8199 59.7983 21.8199C60.1183 21.8199 60.4049 21.7732 60.6583 21.6799C60.9249 21.5732 61.1516 21.4266 61.3383 21.2399C61.5249 21.0532 61.6716 20.8332 61.7783 20.5799C61.8849 20.3266 61.9383 20.0466 61.9383 19.7399V13.4199H64.7783V23.9999H62.0783L61.9983 21.8199L62.4983 21.5799C62.3516 22.0732 62.0849 22.5199 61.6983 22.9199C61.3249 23.3199 60.8783 23.6332 60.3583 23.8599C59.8383 24.0866 59.2783 24.1999 58.6783 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M67.6359 28.3999V13.4199H70.3759L70.4759 15.7799L69.9559 15.5999C70.0359 15.1866 70.2559 14.7999 70.6159 14.4399C70.9893 14.0799 71.4493 13.7866 71.9959 13.5599C72.5426 13.3332 73.1159 13.2199 73.7159 13.2199C74.6359 13.2199 75.4493 13.4532 76.1559 13.9199C76.8759 14.3866 77.4426 15.0332 77.8559 15.8599C78.2693 16.6866 78.4759 17.6399 78.4759 18.7199C78.4759 19.7599 78.2693 20.6932 77.8559 21.5199C77.4426 22.3466 76.8759 22.9999 76.1559 23.4799C75.4359 23.9466 74.6226 24.1799 73.7159 24.1799C73.1026 24.1799 72.5159 24.0599 71.9559 23.8199C71.4093 23.5799 70.9426 23.2732 70.5559 22.8999C70.1693 22.5266 69.9293 22.1199 69.8359 21.6799L70.4959 21.3999V28.3999H67.6359ZM73.0759 21.7399C73.6093 21.7399 74.0693 21.6132 74.4559 21.3599C74.8559 21.1066 75.1626 20.7532 75.3759 20.2999C75.6026 19.8332 75.7159 19.3066 75.7159 18.7199C75.7159 18.1199 75.6026 17.5932 75.3759 17.1399C75.1626 16.6732 74.8559 16.3132 74.4559 16.0599C74.0693 15.8066 73.6093 15.6799 73.0759 15.6799C72.5426 15.6799 72.0759 15.8066 71.6759 16.0599C71.2759 16.3132 70.9626 16.6666 70.7359 17.1199C70.5093 17.5732 70.3959 18.1066 70.3959 18.7199C70.3959 19.3066 70.5093 19.8332 70.7359 20.2999C70.9626 20.7532 71.2759 21.1066 71.6759 21.3599C72.0759 21.6132 72.5426 21.7399 73.0759 21.7399Z"
				fill="#001C3D"
			/>
			<path
				d="M85.6442 24.1999C84.4976 24.1999 83.4909 23.9666 82.6242 23.4999C81.7709 23.0332 81.1042 22.3932 80.6242 21.5799C80.1576 20.7666 79.9242 19.8399 79.9242 18.7999C79.9242 17.9732 80.0576 17.2199 80.3242 16.5399C80.5909 15.8466 80.9642 15.2532 81.4442 14.7599C81.9242 14.2666 82.4909 13.8866 83.1442 13.6199C83.8109 13.3399 84.5376 13.1999 85.3242 13.1999C86.0442 13.1999 86.7042 13.3332 87.3042 13.5999C87.9176 13.8532 88.4442 14.2199 88.8842 14.6999C89.3376 15.1666 89.6842 15.7199 89.9242 16.3599C90.1642 16.9999 90.2776 17.6999 90.2642 18.4599L90.2442 19.3399H81.7442L81.2842 17.5799H87.9042L87.5642 17.9399V17.5199C87.5376 17.1466 87.4176 16.8199 87.2042 16.5399C87.0042 16.2599 86.7442 16.0399 86.4242 15.8799C86.1042 15.7066 85.7509 15.6199 85.3642 15.6199C84.7642 15.6199 84.2576 15.7399 83.8442 15.9799C83.4442 16.2066 83.1376 16.5399 82.9242 16.9799C82.7242 17.4066 82.6242 17.9399 82.6242 18.5799C82.6242 19.2066 82.7576 19.7599 83.0242 20.2399C83.2909 20.7066 83.6709 21.0666 84.1642 21.3199C84.6709 21.5732 85.2642 21.6999 85.9442 21.6999C86.4109 21.6999 86.8376 21.6266 87.2242 21.4799C87.6109 21.3332 88.0309 21.0799 88.4842 20.7199L89.8242 22.6199C89.4509 22.9532 89.0309 23.2399 88.5642 23.4799C88.0976 23.7199 87.6175 23.8999 87.1242 24.0199C86.6309 24.1399 86.1376 24.1999 85.6442 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M92.3625 23.9999V13.4199H95.1025L95.2025 16.8199L94.7225 16.0999C94.8958 15.5666 95.1558 15.0799 95.5025 14.6399C95.8625 14.1999 96.2825 13.8532 96.7625 13.5999C97.2558 13.3332 97.7692 13.1999 98.3025 13.1999C98.5425 13.1999 98.7625 13.2199 98.9625 13.2599C99.1758 13.2866 99.3558 13.3332 99.5025 13.3999L98.7425 16.4999C98.5958 16.4332 98.4025 16.3732 98.1625 16.3199C97.9358 16.2532 97.7025 16.2199 97.4625 16.2199C97.1558 16.2199 96.8625 16.2799 96.5825 16.3999C96.3158 16.5066 96.0758 16.6666 95.8625 16.8799C95.6625 17.0799 95.5025 17.3199 95.3825 17.5999C95.2758 17.8799 95.2225 18.1932 95.2225 18.5399V23.9999H92.3625Z"
				fill="#001C3D"
			/>
			<path
				d="M112.185 24.1999C110.879 24.1999 109.759 23.9666 108.825 23.4999C107.905 23.0199 107.112 22.3266 106.445 21.4199L108.325 19.2999C108.979 20.2066 109.625 20.8266 110.265 21.1599C110.919 21.4799 111.619 21.6399 112.365 21.6399C112.779 21.6399 113.145 21.5799 113.465 21.4599C113.799 21.3399 114.059 21.1732 114.245 20.9599C114.445 20.7466 114.545 20.4999 114.545 20.2199C114.545 20.0066 114.499 19.8132 114.405 19.6399C114.312 19.4532 114.179 19.2932 114.005 19.1599C113.832 19.0132 113.625 18.8866 113.385 18.7799C113.145 18.6599 112.879 18.5599 112.585 18.4799C112.292 18.3999 111.972 18.3266 111.625 18.2599C110.879 18.0999 110.225 17.8932 109.665 17.6399C109.105 17.3866 108.639 17.0799 108.265 16.7199C107.892 16.3599 107.612 15.9466 107.425 15.4799C107.252 14.9999 107.165 14.4666 107.165 13.8799C107.165 13.0532 107.399 12.3332 107.865 11.7199C108.332 11.0932 108.952 10.6066 109.725 10.2599C110.499 9.89991 111.352 9.71991 112.285 9.71991C113.552 9.71991 114.592 9.93325 115.405 10.3599C116.232 10.7866 116.892 11.3866 117.385 12.1599L115.465 14.0399C115.052 13.4666 114.579 13.0332 114.045 12.7399C113.512 12.4466 112.939 12.2999 112.325 12.2999C111.899 12.2999 111.519 12.3599 111.185 12.4799C110.865 12.5866 110.619 12.7532 110.445 12.9799C110.272 13.1932 110.185 13.4466 110.185 13.7399C110.185 13.9799 110.245 14.1932 110.365 14.3799C110.485 14.5666 110.652 14.7332 110.865 14.8799C111.092 15.0132 111.365 15.1332 111.685 15.2399C112.005 15.3466 112.359 15.4332 112.745 15.4999C113.479 15.6466 114.139 15.8466 114.725 16.0999C115.312 16.3399 115.812 16.6332 116.225 16.9799C116.652 17.3266 116.979 17.7266 117.205 18.1799C117.432 18.6332 117.545 19.1399 117.545 19.6999C117.545 20.6466 117.319 21.4532 116.865 22.1199C116.412 22.7866 115.785 23.2999 114.985 23.6599C114.185 24.0199 113.252 24.1999 112.185 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M120.882 23.9999V10.7399H123.723V23.9999H120.882ZM118.902 15.9599V13.4199H125.903V15.9599H118.902Z"
				fill="#001C3D"
			/>
			<path
				d="M127.734 23.9999V13.4199H130.474L130.574 16.8199L130.094 16.0999C130.267 15.5666 130.527 15.0799 130.874 14.6399C131.234 14.1999 131.654 13.8532 132.134 13.5999C132.627 13.3332 133.14 13.1999 133.674 13.1999C133.914 13.1999 134.134 13.2199 134.334 13.2599C134.547 13.2866 134.727 13.3332 134.874 13.3999L134.114 16.4999C133.967 16.4332 133.774 16.3732 133.534 16.3199C133.307 16.2532 133.074 16.2199 132.834 16.2199C132.527 16.2199 132.234 16.2799 131.954 16.3999C131.687 16.5066 131.447 16.6666 131.234 16.8799C131.034 17.0799 130.874 17.3199 130.754 17.5999C130.647 17.8799 130.594 18.1932 130.594 18.5399V23.9999H127.734Z"
				fill="#001C3D"
			/>
			<path
				d="M141.914 23.9999V17.3799C141.914 16.8199 141.741 16.3932 141.394 16.0999C141.061 15.7932 140.607 15.6399 140.034 15.6399C139.487 15.6399 139.001 15.7466 138.574 15.9599C138.147 16.1732 137.747 16.5066 137.374 16.9599L135.634 15.2799C136.274 14.6132 136.987 14.0999 137.774 13.7399C138.574 13.3799 139.441 13.1999 140.374 13.1999C141.227 13.1999 141.967 13.3332 142.594 13.5999C143.234 13.8666 143.727 14.2666 144.074 14.7999C144.421 15.3332 144.594 16.0066 144.594 16.8199V23.9999H141.914ZM139.014 24.1999C138.347 24.1999 137.741 24.0666 137.194 23.7999C136.661 23.5332 136.234 23.1666 135.914 22.6999C135.607 22.2199 135.454 21.6599 135.454 21.0199C135.454 20.4732 135.554 19.9932 135.754 19.5799C135.954 19.1532 136.247 18.8066 136.634 18.5399C137.034 18.2599 137.527 18.0466 138.114 17.8999C138.714 17.7532 139.407 17.6799 140.194 17.6799H143.314L143.134 19.5799H140.154C139.834 19.5799 139.547 19.6066 139.294 19.6599C139.054 19.7132 138.847 19.7999 138.674 19.9199C138.514 20.0399 138.387 20.1799 138.294 20.3399C138.214 20.4999 138.174 20.6866 138.174 20.8999C138.174 21.1266 138.234 21.3266 138.354 21.4999C138.474 21.6732 138.634 21.8066 138.834 21.8999C139.047 21.9932 139.287 22.0399 139.554 22.0399C139.941 22.0399 140.307 21.9732 140.654 21.8399C141.014 21.7066 141.334 21.5199 141.614 21.2799C141.894 21.0399 142.114 20.7666 142.274 20.4599L142.814 21.8799C142.534 22.3332 142.194 22.7332 141.794 23.0799C141.394 23.4266 140.961 23.6999 140.494 23.8999C140.027 24.0999 139.534 24.1999 139.014 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M148.168 23.9999V10.7399H151.008V23.9999H148.168ZM146.188 15.9599V13.4199H153.188V15.9599H146.188Z"
				fill="#001C3D"
			/>
			<path
				d="M159.941 24.1999C158.794 24.1999 157.788 23.9666 156.921 23.4999C156.068 23.0332 155.401 22.3932 154.921 21.5799C154.454 20.7666 154.221 19.8399 154.221 18.7999C154.221 17.9732 154.354 17.2199 154.621 16.5399C154.888 15.8466 155.261 15.2532 155.741 14.7599C156.221 14.2666 156.788 13.8866 157.441 13.6199C158.108 13.3399 158.834 13.1999 159.621 13.1999C160.341 13.1999 161.001 13.3332 161.601 13.5999C162.214 13.8532 162.741 14.2199 163.181 14.6999C163.634 15.1666 163.981 15.7199 164.221 16.3599C164.461 16.9999 164.574 17.6999 164.561 18.4599L164.541 19.3399H156.041L155.581 17.5799H162.201L161.861 17.9399V17.5199C161.834 17.1466 161.714 16.8199 161.501 16.5399C161.301 16.2599 161.041 16.0399 160.721 15.8799C160.401 15.7066 160.048 15.6199 159.661 15.6199C159.061 15.6199 158.554 15.7399 158.141 15.9799C157.741 16.2066 157.434 16.5399 157.221 16.9799C157.021 17.4066 156.921 17.9399 156.921 18.5799C156.921 19.2066 157.054 19.7599 157.321 20.2399C157.588 20.7066 157.968 21.0666 158.461 21.3199C158.968 21.5732 159.561 21.6999 160.241 21.6999C160.708 21.6999 161.134 21.6266 161.521 21.4799C161.908 21.3332 162.328 21.0799 162.781 20.7199L164.121 22.6199C163.748 22.9532 163.328 23.2399 162.861 23.4799C162.394 23.7199 161.914 23.8999 161.421 24.0199C160.928 24.1399 160.434 24.1999 159.941 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M171.382 28.5999C170.555 28.5999 169.728 28.4799 168.902 28.2399C168.088 27.9999 167.428 27.6799 166.922 27.2799L167.922 25.2399C168.348 25.5332 168.835 25.7666 169.382 25.9399C169.942 26.1132 170.515 26.1999 171.102 26.1999C171.808 26.1999 172.382 26.0932 172.822 25.8799C173.275 25.6666 173.608 25.3466 173.822 24.9199C174.048 24.5066 174.162 23.9799 174.162 23.3399V21.4999L174.642 21.6199C174.562 22.0332 174.335 22.4266 173.962 22.7999C173.602 23.1599 173.155 23.4532 172.622 23.6799C172.088 23.9066 171.528 24.0199 170.942 24.0199C169.968 24.0199 169.095 23.7932 168.322 23.3399C167.562 22.8732 166.962 22.2399 166.522 21.4399C166.082 20.6399 165.862 19.7199 165.862 18.6799C165.862 17.6132 166.082 16.6666 166.522 15.8399C166.962 15.0132 167.555 14.3666 168.302 13.8999C169.062 13.4332 169.915 13.1999 170.862 13.1999C171.262 13.1999 171.642 13.2466 172.002 13.3399C172.375 13.4332 172.715 13.5666 173.022 13.7399C173.342 13.8999 173.628 14.0799 173.882 14.2799C174.135 14.4799 174.342 14.6999 174.502 14.9399C174.662 15.1799 174.762 15.4199 174.802 15.6599L174.202 15.8399L174.282 13.4199H177.022V23.1999C177.022 24.0532 176.888 24.8132 176.622 25.4799C176.368 26.1599 175.995 26.7266 175.502 27.1799C175.022 27.6466 174.435 27.9999 173.742 28.2399C173.048 28.4799 172.262 28.5999 171.382 28.5999ZM171.462 21.7199C172.022 21.7199 172.508 21.5932 172.922 21.3399C173.348 21.0866 173.675 20.7332 173.902 20.2799C174.142 19.8266 174.262 19.2999 174.262 18.6999C174.262 18.0866 174.142 17.5532 173.902 17.0999C173.675 16.6466 173.348 16.2932 172.922 16.0399C172.508 15.7732 172.022 15.6399 171.462 15.6399C170.915 15.6399 170.435 15.7732 170.022 16.0399C169.608 16.2932 169.282 16.6466 169.042 17.0999C168.815 17.5532 168.702 18.0799 168.702 18.6799C168.702 19.2799 168.815 19.8132 169.042 20.2799C169.282 20.7332 169.608 21.0866 170.022 21.3399C170.435 21.5932 170.915 21.7199 171.462 21.7199Z"
				fill="#001C3D"
			/>
			<path
				d="M181.323 28.5999L183.643 23.1799L183.683 24.8399L178.503 13.4199H181.723L184.323 19.5599C184.417 19.7732 184.517 20.0332 184.623 20.3399C184.73 20.6466 184.81 20.9399 184.863 21.2199L184.383 21.4199C184.477 21.1932 184.57 20.9332 184.663 20.6399C184.77 20.3466 184.877 20.0332 184.983 19.6999L187.203 13.4199H190.463L186.023 23.9999L184.203 28.5999H181.323Z"
				fill="#001C3D"
			/>
			<path
				d="M198.505 23.9999V9.99991H204.705C205.585 9.99991 206.371 10.1932 207.065 10.5799C207.771 10.9666 208.331 11.4999 208.745 12.1799C209.158 12.8466 209.365 13.6132 209.365 14.4799C209.365 15.3199 209.158 16.0799 208.745 16.7599C208.331 17.4399 207.771 17.9799 207.065 18.3799C206.371 18.7799 205.585 18.9799 204.705 18.9799H201.385V23.9999H198.505ZM206.465 23.9999L202.905 17.6599L205.965 17.1599L209.925 24.0199L206.465 23.9999ZM201.385 16.5199H204.585C204.945 16.5199 205.258 16.4399 205.525 16.2799C205.805 16.1066 206.018 15.8732 206.165 15.5799C206.325 15.2866 206.405 14.9532 206.405 14.5799C206.405 14.1932 206.311 13.8532 206.125 13.5599C205.938 13.2532 205.678 13.0199 205.345 12.8599C205.011 12.6866 204.625 12.5999 204.185 12.5999H201.385V16.5199Z"
				fill="#001C3D"
			/>
			<path
				d="M216.758 24.1999C215.611 24.1999 214.604 23.9666 213.738 23.4999C212.884 23.0332 212.217 22.3932 211.738 21.5799C211.271 20.7666 211.038 19.8399 211.038 18.7999C211.038 17.9732 211.171 17.2199 211.438 16.5399C211.704 15.8466 212.077 15.2532 212.557 14.7599C213.037 14.2666 213.604 13.8866 214.258 13.6199C214.924 13.3399 215.651 13.1999 216.438 13.1999C217.158 13.1999 217.817 13.3332 218.417 13.5999C219.031 13.8532 219.557 14.2199 219.997 14.6999C220.451 15.1666 220.798 15.7199 221.038 16.3599C221.278 16.9999 221.391 17.6999 221.378 18.4599L221.357 19.3399H212.857L212.398 17.5799H219.018L218.678 17.9399V17.5199C218.651 17.1466 218.531 16.8199 218.318 16.5399C218.118 16.2599 217.858 16.0399 217.538 15.8799C217.217 15.7066 216.864 15.6199 216.477 15.6199C215.877 15.6199 215.371 15.7399 214.958 15.9799C214.558 16.2066 214.251 16.5399 214.038 16.9799C213.838 17.4066 213.738 17.9399 213.738 18.5799C213.738 19.2066 213.871 19.7599 214.137 20.2399C214.404 20.7066 214.784 21.0666 215.277 21.3199C215.784 21.5732 216.378 21.6999 217.057 21.6999C217.524 21.6999 217.951 21.6266 218.337 21.4799C218.724 21.3332 219.144 21.0799 219.598 20.7199L220.938 22.6199C220.564 22.9532 220.144 23.2399 219.678 23.4799C219.211 23.7199 218.731 23.8999 218.238 24.0199C217.744 24.1399 217.251 24.1999 216.758 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M226.62 23.9999L222.18 13.4199H225.28L228.1 21.2799L227.46 21.3999L230.42 13.4199H233.46L228.8 23.9999H226.62Z"
				fill="#001C3D"
			/>
			<path
				d="M235.435 23.9999V13.4199H238.275V23.9999H235.435ZM236.855 11.2399C236.295 11.2399 235.861 11.1066 235.555 10.8399C235.248 10.5732 235.095 10.1932 235.095 9.69991C235.095 9.24658 235.248 8.87991 235.555 8.59991C235.875 8.30658 236.308 8.15991 236.855 8.15991C237.388 8.15991 237.808 8.29325 238.115 8.55991C238.421 8.82658 238.575 9.20658 238.575 9.69991C238.575 10.1666 238.421 10.5399 238.115 10.8199C237.808 11.0999 237.388 11.2399 236.855 11.2399Z"
				fill="#001C3D"
			/>
			<path
				d="M246.347 24.1999C245.201 24.1999 244.194 23.9666 243.327 23.4999C242.474 23.0332 241.807 22.3932 241.327 21.5799C240.861 20.7666 240.627 19.8399 240.627 18.7999C240.627 17.9732 240.761 17.2199 241.027 16.5399C241.294 15.8466 241.667 15.2532 242.147 14.7599C242.627 14.2666 243.194 13.8866 243.847 13.6199C244.514 13.3399 245.241 13.1999 246.027 13.1999C246.747 13.1999 247.407 13.3332 248.007 13.5999C248.621 13.8532 249.147 14.2199 249.587 14.6999C250.041 15.1666 250.387 15.7199 250.627 16.3599C250.867 16.9999 250.981 17.6999 250.967 18.4599L250.947 19.3399H242.447L241.987 17.5799H248.607L248.267 17.9399V17.5199C248.241 17.1466 248.121 16.8199 247.907 16.5399C247.707 16.2599 247.447 16.0399 247.127 15.8799C246.807 15.7066 246.454 15.6199 246.067 15.6199C245.467 15.6199 244.961 15.7399 244.547 15.9799C244.147 16.2066 243.841 16.5399 243.627 16.9799C243.427 17.4066 243.327 17.9399 243.327 18.5799C243.327 19.2066 243.461 19.7599 243.727 20.2399C243.994 20.7066 244.374 21.0666 244.867 21.3199C245.374 21.5732 245.967 21.6999 246.647 21.6999C247.114 21.6999 247.541 21.6266 247.927 21.4799C248.314 21.3332 248.734 21.0799 249.187 20.7199L250.527 22.6199C250.154 22.9532 249.734 23.2399 249.267 23.4799C248.801 23.7199 248.321 23.8999 247.827 24.0199C247.334 24.1399 246.841 24.1999 246.347 24.1999Z"
				fill="#001C3D"
			/>
			<path
				d="M255.29 23.9999L251.77 13.4199H254.71L256.87 20.5999L256.43 20.5399L258.83 14.9399H260.49L262.99 20.5599L262.49 20.5999L264.65 13.4199H267.59L264.01 23.9999H261.95L259.43 17.9799L259.77 18.0399L257.33 23.9999H255.29Z"
				fill="#001C3D"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_656_8443"
					x1="0.0820322"
					y1="32.7039"
					x2="37.8455"
					y2="25.5375"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.00833203" stopColor="#00509A" />
					<stop offset="0.5224" stopColor="#227ED3" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Logo;
