import { StepEnum } from "../types/StepEnum";

export const getQuizTitle = (currentStep: number) => {
  switch (currentStep) {
    case StepEnum.FIRST:
      return "Select your approximate super balance to begin";
    case StepEnum.SECOND:
      return "Please enter your age";
    case StepEnum.THIRD:
      return "What is your employment status?";
    case StepEnum.FOURTH:
      return "What is your annual before tax income?";
    case StepEnum.FIFTH:
      return "How much per week would you like to retire on?";
    case StepEnum.CALCULATING:
      return "Calculating Results...";
    default:
      return "";
  }
};
