import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { StepIcon } from "../../assets/icons/StepIcon";
import { StepDoneIcon } from "../../assets/icons/StepDoneIcon";

const numbers = Array.from({ length: 5 }, (_, index) => index + 1);

interface StepsProps {
	currentStep: number;
}

export const Steps: FC<StepsProps> = ({ currentStep }) => {
	return (
		<Flex w={"100%"} justifyContent={"space-between"}>
			{numbers.map((number, index) => {
				return (
					<Flex
						width={
							index !== numbers.length - 1 ? "100%" : "-webkit-fit-content"
						}
						alignItems={"center"}
						key={index}
					>
						<Flex
							w={index !== numbers.length - 1 ? 10 : "-webkit-fit-content"}
							opacity={currentStep >= number ? 1 : 0.2}
							alignItems={"center"}
							justifyContent={"center"}
							h={10}
							style={{ transition: "0.5s ease-in-out" }}
						>
							{number < currentStep && <StepDoneIcon />}
							{currentStep <= number && (
								<StepIcon x={number === 4 ? 13 : null} number={number} />
							)}
						</Flex>
						{index !== numbers.length - 1 && (
							<Box
								borderRadius={"2px"}
								overflow={"hidden"}
								w={"100%"}
								h={"2px"}
								bg={"#CCD2D8"}
							>
								<Box
									style={{ transition: "0.5s ease-in-out" }}
									w={currentStep >= number + 1 ? "100%" : "0"}
									bg={"dark"}
									h={"2px"}
								/>
							</Box>
						)}
					</Flex>
				);
			})}
		</Flex>
	);
};
